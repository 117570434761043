.carousel {
  margin-left: $layout-side-padding * -1;
  margin-right: $layout-side-padding * -1;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: scroll;
  padding: 1rem 0;
  scroll-snap-type: mandatory;
  scroll-snap-destination: 50% 0;
  width: calc(100% + #{$layout-side-padding * 2});

  @include respond-to('medium') {
    margin: vr(10) 0;
    overflow: visible;
    padding: 0;
    position: relative;
    scroll-snap-type: none;
    scroll-snap-destination: 0 0;
    width: 100%;
  }
}

.carousel-list {
  @include list-unstyled;
  display: flex;
  margin: 0;
  max-width: none;
  padding: 0 10vw;

  @include respond-to('small') {
    padding: 0 20vw;
  }

  @include respond-to('medium') {
    display: block;
    padding: 0;
    position: relative;
  }
}

.carousel-item {
  line-height: 0;
  padding: 0;
  scroll-snap-coordinate: 50% 0;
  width: 80vw;

  @include respond-to('small') {
    width: 60vw;
  }

  @include respond-to('medium') {
    @include carousel-card-transform(-50%);
    box-shadow: 0 0 32px rgba(#000, .2);
    left: 50%;
    position: absolute;
    scroll-snap-coordinate: none;
    top: 0;
    transform-origin: left center;
    transition:
      transform ($timing-base * 2) ease 0s,
      z-index 0s linear 0s;
    width: 35rem;
    z-index: 1;

    &[data-item="first"] {
      @include carousel-card-transform(-50%, 1);
      transition:
        transform ($timing-base * 2) ease 0s,
        z-index 0s linear $timing-base;
      z-index: 3;

      &[data-direction="prev"],
      &[data-direction="next"] {
        &::before {
          transition: opacity ($timing-base / 2) ease 0s;
        }
      }

      &[data-direction="next"] {
        &::before {
          @include carousel-shadow(right);
        }
      }

      &[data-direction="prev"] {
        &::before {
          @include carousel-shadow(left);
        }
      }
    }

    &[data-item="second"],
    &[data-item="last"] {
      transition:
        transform ($timing-base * 2) ease 0s,
        z-index 0s linear $timing-base;
      z-index: 2;

      &::before,
      &::after {
        opacity: 1;
      }
    }

    &[data-item="second"] {
      @include carousel-card-transform(0);

      &::before {
        @include carousel-shadow(right);
      }
    }

    &[data-item="last"] {
      @include carousel-card-transform(-100%);

      &::before {
        @include carousel-shadow(left);
      }
    }

    &::before,
    &::after {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      margin: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform: translate3d(0, 0, 0);
      transition: opacity ($timing-base / 2) ease ($timing-base / 2);
      width: 100%;
      z-index: 10;
    }

    &::before {
      background: linear-gradient(
        to left,
        rgba(#000, 0) 20%,
        rgba(#000, 0) 60%
      );
    }

    &::after {
      background-color: rgba(#000, .2);
    }
  }

  @media (min-width: 56.25em) {
    &[data-item="second"] {
      @include carousel-card-transform(15%);
    }

    &[data-item="last"] {
      @include carousel-card-transform(-115%);
    }
  }

  @media (min-width: 62.5em) {
    &[data-item="second"] {
      @include carousel-card-transform(30%);
    }

    &[data-item="last"] {
      @include carousel-card-transform(-130%);
    }
  }

  @media (min-width: 68.75em) {
    &[data-item="second"] {
      @include carousel-card-transform(50%);
    }

    &[data-item="last"] {
      @include carousel-card-transform(-150%);
    }
  }
}

.carousel-item-inner {
  background-color: #fff;
  margin: 0 1px;

  @include respond-to('small') {
    box-shadow: 0 0 10px rgba(#000, .2);
    margin: 0 10px;
  }

  @include respond-to('medium') {
    box-shadow: none;
    margin: 0;
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-image {
  height: 0;
  line-height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.carousel-item-heading {
  @include font-16;
  @include background-triangles;
  color: #fff;
  margin: 0;
  padding: vr(2) 0;
  text-align: center;
  text-transform: uppercase;
}

.carousel-item:nth-child(4n+1) .carousel-item-heading {
  background-color: palette(blue);
  background-image: url(/sites/all/themes/rotary_rotaryorg/images/geo-blue.png);
}

.carousel-item:nth-child(4n+2) .carousel-item-heading {
  background-color: palette(green);
  background-image: url(/sites/all/themes/rotary_rotaryorg/images/geo-green.png);
}

.carousel-item:nth-child(4n+3) .carousel-item-heading {
  background-color: palette(red);
  background-image: url(/sites/all/themes/rotary_rotaryorg/images/geo-red.png);
}

.carousel-item:nth-child(4n+4) .carousel-item-heading {
  background-color: palette(purple);
  background-image: url(/sites/all/themes/rotary_rotaryorg/images/geo-purple.png);
}

.carousel-item-text {
  @include font-16;
  background-color: #fff;
  padding: vr(4) 3rem;

  a {
    @include font-14;
    text-transform: uppercase;

    @include hover {
      @include standard-hover;
    }
  }

  p {
    margin: 0;

    + p {
      margin-top: vr(4);
    }
  }
}

.carousel-button-prev,
.carousel-button-next {
  display: none;

  @include respond-to('medium') {
    @include hide-text;
    appearance: none;
    background: transparent url(/sites/all/themes/rotary_rotaryorg/images/carousel-arrow.svg) no-repeat 50% 50%;
    background-size: 72px 31px;
    border: none;
    display: block;
    height: 7.1rem;
    margin-top: -3.5rem;
    padding: 0;
    position: absolute;
    top: 50%;
    transition: $transition--basic;
    width: 11.2rem;
    z-index: 100;
  }

  &:hover {
    background-color: rgba(#000, .05);
    background-position: 50% 40%;
  }
}

$carousel-button-sm: -400px;
$carousel-button-md: -470px;
$carousel-button-lg: -515px;
$carousel-button-xl: -575px;

.carousel-button-next {
  transform: rotate(90deg);
  margin-right: $carousel-button-sm;
  right: 50%;

  @media (min-width: 56.25em) {
    margin-right: $carousel-button-md;
  }

  @media (min-width: 62.5em) {
    margin-right: $carousel-button-lg;
  }

  @media (min-width: 68.75em) {
    margin-right: $carousel-button-xl;
  }
}

.carousel-button-prev {
  transform: rotate(-90deg);
  left: 50%;
  margin-left: $carousel-button-sm;

  @media (min-width: 56.25em) {
    margin-left: $carousel-button-md;
  }

  @media (min-width: 62.5em) {
    margin-left: $carousel-button-lg;
  }

  @media (min-width: 68.75em) {
    margin-left: $carousel-button-xl;
  }
}
