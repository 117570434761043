.site-footer {
  @include font-16;
  color: palette(gray);

  a {
    color: palette(gray);
    font-weight: $font-weight--normal;

    @include hover {
      background-color: transparent;
      text-decoration: underline;
    }
  }
}

.site-footer-nav {
  @include list-unstyled;

  &.-language-switcher {
    margin-top: vr(-3);
    margin-bottom: vr(-3);
  }
}

.site-footer-nav-item {
  @include respond-to('small') {
    display: inline-block;

    + .site-footer-nav-item {
      margin-left: 4rem;

      &.-margin-reduced {
        margin-left: 2rem;
      }
    }
  }
}

.site-footer-legal {
  @include font-12;

  a {
    padding: 0 vr(.5);
    text-decoration: underline;
  }
}

.site-footer-social {
  @include clearfix;
  @include list-unstyled;
  display: flex;
  justify-content: center;

  a {
    display: block;
    line-height: 0;
    padding: 0 1rem vr(1);

    @include respond-to('small') {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    @include hover {
      img {
        filter: unset;
      }
    }
  }

  img {
    height: 24px;
    filter:
      // reset the color to base gray
      grayscale(100%)
      contrast(0)

      // tweak the color
      brightness(1.2)
      sepia(20%)
      hue-rotate(150deg);
    transition: $transition--basic;
    width: auto;
  }

  li {
    margin: 0;
  }
}
