@mixin carousel-shadow($dir) {
  background: linear-gradient(
    to $dir,
    rgba(#000, .6) 0,
    rgba(#000, 0) 50%
  );
}

@mixin carousel-card-transform($xPos, $scale: .85714) {
  transform:
    scale($scale)
    translate3d($xPos, 0, 0);
}
