.site-header {
  background-color: #fff;
  box-shadow: 0 0 30px rgba(#000, .2);
  position: relative;
  z-index: $zindex--header;

  @include respond-to('medium-large') {
    box-shadow: none;
    margin-bottom: $nav-height--large;
    padding: 0 $layout-side-padding;
  }
}

.site-header-container {
  height: $header-height--small;
  line-height: 1;
  margin: 0 auto;
  max-width: width('xxx-wide');
  padding: 1rem 0 0;
  text-align: center;

  [data-mobile="navigation"] & {
    position: relative;
    z-index: 2;
  }

  [data-mobile="search"] & {
    box-shadow: 0 0 30px rgba(#000, .3);
    position: relative;
    z-index: 2;
  }

  @include respond-to('medium-large') {
    height: $header-height--large;
    padding-top: 2rem;
    text-align: left;
    max-width: $header-container-max-width-desktop;
  }
}

.site-header-logo {
  display: inline-block;
  line-height: 0;
  margin: 0;
  position: relative;
  vertical-align: middle;
  z-index: 2;

  @include respond-to('medium-large') {
    margin: 0;
  }

  a,
  span {
    @include hide-text;
    background: none;
    background-size: 10.8rem 4rem;
    display: block;
    height: 4rem;
    transition: none;
    width: 10.8rem;
    text-indent: 0px;
    &:hover,
    [data-whatinput="keyboard"] &:focus {
      background-color: transparent;
    }
  }
}

.site-nav-toggle-search,
.site-nav-toggle-search-desktop,
.site-nav-toggle-mobile {
  @include font-10;
  appearance: none;
  background-color: transparent;
  border: none;
  color: palette(blue);
  font-weight: $font-weight--bold;
  height: $header-height--small;
  line-height: 1rem;
  margin: 0;
  padding: 2.4rem 0 0;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: $header-height--small;

  @include respond-to('medium-large') {
    font-size: 1.4rem;
    line-height: 2.4rem;
    position: absolute;
    right: unset;
  }
}
.site-nav-toggle-search-desktop {
  width: auto;
}


.site-nav-toggle-search {
  border-left: 1px solid palette(gray, x-light);
  right: 0;
  transition: color .2s $easing-base;

  [data-mobile="search"] & {
    color: palette(gray, dark);
  }
}

.site-nav-toggle-search-icon {
  display: block;
  height: 18px;
  left: 50%;
  margin: -9px 0 0 -9px;
  position: absolute;
  top: 20px;
  width: 18px;

  @include respond-to('medium-large') {
    top: 44px;
    left: 0;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    transform-origin: center center;
    transition: all .2s ease-in;

    [data-mobile="search"] & {
      transition-timing-function: ease-out;
    }
  }

  &::before {
    border: 2px solid palette(blue);
    border-radius: 50%;
    height: 12px;
    top: 1px;
    left: 1px;
    width: 12px;

    [data-mobile="search"] & {
      border-color: palette(gray, dark);
      border-radius: 0;
      border-width: 2px 0 0;
      height: 2px;
      left: 50%;
      margin: -1px 0 0 -12px;
      top: 50%;
      transform: rotate(-45deg);
      width: 24px;
    }
  }

  &::after {
    background-color: palette(blue);
    border-radius: 0 2px 2px 0;
    height: 3px;
    left: 50%;
    margin-top: 3px;
    top: 50%;
    transform: rotate(45deg);
    width: 9px;

    [data-mobile="search"] & {
      background-color: palette(gray, dark);
      height: 2px;
      margin: -1px 0 0 -12px;
      width: 24px;
    }
  }
}

.site-nav-toggle-mobile {
  border-right: 1px solid palette(gray, x-light);
  left: 0;
  transition: color .1s $easing-base .2s;

  [data-mobile="navigation"] & {
    color: palette(gray, dark);
  }
}

.site-nav-toggle-mobile-icon {
  display: block;
  height: 14px;
  left: 50%;
  margin: -7px 0 0 -12px;
  position: absolute;
  top: 20px;
  transform-origin: center center;
  transition: transform $timing-base cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  width: 24px;

  [data-mobile="navigation"] & {
    transform: rotate(180deg);
    transition: transform $timing-base cubic-bezier(0.215, 0.61, 0.355, 1) .1s;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    left: 50%;
    margin-left: -12px;
    position: absolute;
    top: 50%;
    transform-origin: center center;
    width: 24px;
  }

  &::before {
    border-bottom: 2px solid palette(blue);
    border-top: 2px solid palette(blue);
    height: 14px;
    margin-top: -7px;
    transition:
      border-color .2s ease-in .1s,
      border-width 0s ease-in $timing-base,
      height .1s ease-in $timing-base,
      margin .1s ease-in $timing-base,
      transform .2s ease-in .1s;

    [data-mobile="navigation"] & {
      border-color: palette(gray, dark);
      border-width: 1px 0;
      height: 0;
      margin-top: -1px;
      transition:
        border-color $timing-base ease-out .1s,
        border-width 0s ease-out .1s,
        height .1s ease-out,
        margin .1s ease-out,
        transform $timing-base ease-out .1s;
      transform: rotate(45deg);
    }
  }

  &::after {
    background-color: palette(blue);
    height: 2px;
    margin-top: -1px;
    transition:
      background .2s ease-in .1s,
      transform .2s ease-in .1s;

    [data-mobile="navigation"] & {
      background-color: palette(gray, dark);
      transform: rotate(-45deg);
      transition:
        background $timing-base ease-in .1s,
        transform $timing-base ease-out .1s;
    }
  }
}


/*
  --------------------
  Header messages
  --------------------
*/
.header-message-wrapper{
  background-color:  white;
  position: relative;
  z-index: 101;

  body[data-mobile="navigation"] & {
    display: none;
  }
}
.header-message {
  font-size: 1.3rem;
  line-height: vr(3);
  padding: vr(3) 1.5rem;
  position: relative;
  z-index: ($zindex--header + 1);

  p {
    margin: 0;
    max-width: none;

    + p {
      margin-top: vr(2);
    }
  }

  strong {
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  &.-with-button {
    padding-right: 12rem;
  }

  &.-alert {
    background-color: palette(red, xx-light);
    color: lighten(palette(red), 2);

    a {
      color: lighten(palette(red), 2);
      text-decoration: underline;

      &:hover,
      [data-whatinput="keyboard"] &:focus {
        text-decoration: none;
        background-color: darken(palette(red, xx-light), 8);
      }
    }
    &.theme-blue{
      background-color: #f5f7ff;
      color: #000;

      a {
        color: #17458F;
        &:hover,
        [data-whatinput="keyboard"] &:focus {
          background-color: #e5f5fa;
        }
      }
    }
  }

  &.-information {
    background-color: palette(blue);
    color: #fff;

    a {
      color: #fff;
      text-decoration: underline;

      &:hover,
      [data-whatinput="keyboard"] &:focus {
        background-color: transparent;
      }
    }
  }
}

.header-message-button {
  appearance: none;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  font-weight: $font-weight--bold;
  height: 100%;
  margin: 0;
  padding: 0 3rem 0 1.5rem;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
  transition: $transition--basic;

  // close x
  &::before,
  &::after {
    content: '';
    display: block;
    height: 1.2rem;
    margin-top: -.6rem;
    position: absolute;
    right: 1.5rem;
    top: 50%;
    width: .2rem;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  // colors for alert banner
  .-alert & {
    color: lighten(palette(red), 2);

    @include hover {
      background-color: darken(palette(red, xx-light), 8);
    }

    &::before,
    &::after {
      background-color: lighten(palette(red), 2);
    }

    @include respond-to('medium-large') {
      background-color: darken(palette(red, xx-light), 4);
    }
  }

  // colors for info banner
  .-information & {
    color: #fff;

    &::before,
    &::after {
      background-color: #fff;
    }

    @include hover {
      background-color: darken(palette(blue), 8);
    }

    @include respond-to('medium-large') {
      background-color: darken(palette(blue), 4);
    }
  }
}
