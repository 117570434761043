.text-stat {
  @include clearfix;
  margin: vr(10) auto;
}

.text-stat-list {
  @include list-unstyled;
  max-width: none;
  margin: 0;
  position: relative;

  li {
    line-height: 2.6rem;
    margin: 0;
    padding: vr(4) 0;
    text-align: center;
    width: 100%;

    > span {
      display: inline-block;
      opacity: 0;
      transition: all ($timing-base * 3) $easing-base;

      [data-waypoint="visible"] & {
        opacity: 1;
      }
    }

    &:nth-child(2) {
      > span {
        transition-delay: $timing-base;
      }
    }

    &:nth-child(3) {
      > span {
        transition-delay: $timing-base * 2;
      }
    }

    &:nth-child(4) {
      > span {
        transition-delay: $timing-base * 3;
      }
    }
  }
}
@media (min-width:350px){
.text-stat .text-stat-description {
  display: inline-block;
  max-width: 35rem;
}
}

@media (min-width:480px){
.text-stat .text-stat-list {
  display: flex;
  flex-wrap: wrap;
}
}

.text-stat-number {
  display: block;
  font-size: 4.8rem;
  font-weight: $font-weight--light;
  line-height: vr(10);
  margin-bottom: 0;
  margin-top: 0;

  @media (min-width:250px) {
    font-size: 6.6rem;
    line-height: vr(12);
  }

  @media (min-width:300px) {
    font-size: 8rem;
    line-height: vr(15);
  }
}

// colors
.text-stat-list li:first-child {
  color: palette(purple);
}

.text-stat-list li:nth-child(2) {
  color: palette(green);
}

.text-stat-list li:nth-child(3) {
  color: palette(red);
}

.text-stat-list li:last-child {
  color: palette(blue, x-dark);
}

// 1x
.text-stat .text-stat-list {
  li:nth-last-child(1):first-child {
    border-top: none;
    margin: 0;
    width: 100%;
  }
}

// 2x
@media (min-width: 480px){
.text-stat .text-stat-list {
  li:nth-last-child(2):first-child,
  li:nth-last-child(2):first-child ~ li {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 22rem;
    border-top: none;
    margin: 0;
    padding: 0 vr(8);
    width: percentage(1/2);

    .text-stat-number {
      margin: 0 vr(-8);
    }
  }

  li:nth-last-child(2):first-child ~ li {
    border-left: 1px solid palette(gray, light);
    width: calc(percentage(1/2) - 1px);
  }
}
}
//3x
@media (min-width:480px){
.text-stat .text-stat-list {
  li:nth-last-child(3):first-child,
  li:nth-last-child(3):first-child ~ li  {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 22rem;
    border-top: none;
    margin: 0;
    padding: 0 vr(8);
    width: percentage(1/2);

    .text-stat-wrapper {
      padding: 0 vr(2);
      width: 100%;
    }
      
    .text-stat-number {
      margin: 0 vr(-8);
    }
     
    &:first-child {
       margin-bottom: -220px;
    }
  }
    
  li:nth-last-child(3):first-child ~ li {
      width: calc(percentage(1/2) - 1px);
      &:last-child {
        margin-left: auto;
        border-top: 1px solid palette(gray, light);
      }
    }

  li:nth-last-child(3):first-child::after,
  li:nth-child(3)::after {
    background-color: palette(gray, light);
    content: '';
    display: block;
    position: absolute;
  }

  li:nth-child(3) {
    &::after {
      left: 50%;
      height: 100%;
      top: 0;
      width: 1px;
    }
  }
     }
}
// 4x
@media (min-width: 480px){
.text-stat .text-stat-list {
  li:nth-last-child(4):first-child,
  li:nth-last-child(4):first-child ~ li {
    align-items: flex-start;
    border-top: none;
    display: flex;
    justify-content: center;
    margin: 0;
    min-height: 22rem;
    padding: vr(8) 0;
    width: percentage(1/2);

    .text-stat-wrapper {
      padding: 0 vr(8);
      width: 100%;
    }

    .text-stat-number {
      margin: 0 vr(-8);
    }
  }

  li:nth-last-child(4):first-child {
    position: relative;

    &::after {
      bottom: 0;
      height: 1px;
      left: 0;
      width: 200%;
    }
  }

  li:nth-last-child(4):first-child::after,
  li:nth-child(4)::after {
    background-color: palette(gray, light);
    content: '';
    display: block;
    position: absolute;
  }

  li:nth-child(4) {
    &::after {
      height: 100%;
      left: 50%;
      top: 0;
      width: 1px;
    }
  }
}
}

@media (min-width:600px) {
  .text-stat .text-stat-list {
    li:nth-last-child(3):first-child,
    li:nth-last-child(3):first-child ~ li {
    &:first-child {
      margin-bottom: -320px;
    }
  }

  li:nth-last-child(3):first-child,
  li:nth-last-child(3):first-child ~ li,
  li:nth-last-child(4):first-child,
  li:nth-last-child(4):first-child ~ li {
    min-height: 32rem;
    align-items: center;
  }
}
}

@media (min-width:840px) {
  .text-stat .text-stat-list {
      li:nth-last-child(3):first-child,
      li:nth-last-child(3):first-child ~ li {
      &:first-child {
        margin-bottom: -350px;
      }
    }
  }
}