.pagination {
  @include list-unstyled;
  align-items: center;
  color: palette(gray);
  display: flex;
  height: 3rem;
  justify-content: center;

  li {
    margin: 0;

    &.current {
      a {
        background-color: palette(blue);
        border-color: palette(blue);
        color: #fff;
      }
    }

    + li {
      margin-left: .4rem;
    }

    a,
    > span {
      background-color: transparent;
      border: 1px solid transparent;
      box-sizing: content-box;
      display: block;
      font-weight: $font-weight--normal;
      line-height: 2.8rem;
      text-align: center;
      min-width: 2.8rem;
    }

    a {
      color: palette(blue);
      transition: $transition--basic;

      @include hover {
        border-color: palette(blue);
      }
    }
  }
}
