/*
  --------------------
  Trigger links
  --------------------
*/

.modal-trigger {
  &.-inline {
    background-position: vr(1) vr(.6);
    background-repeat: no-repeat;
    display: inline-block;
    padding: 0 vr(1);
    transition:
      background-color $timing-base $easing-base,
      color $timing-base $easing-base;

    @include respond-to('small') {
      background-position: vr(1) vr(1);
    }

    &.-hover {
      background-color: palette(blue, x-light);
    }

    &.-image {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/icon-magnify-blue.svg);
      background-size: 18px 18px;
      padding-left: calc(18px + #{vr(2)});
    }

    &.-slideshow {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/slideshow-blue.svg);
      background-size: 20px 18px;
      padding-left: calc(20px + #{vr(2)});
    }

    &.-video {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/video-play-blue.svg);
      background-position: vr(1) calc(#{vr(.6)} + .1rem);
      background-size: 18px 18px;
      padding-left: calc(18px + #{vr(2)});

      @include respond-to('small') {
        background-position: vr(1) calc(#{vr(1)} + .1rem);
      }
    }

    &.-launch {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/launch-white.svg);
      background-size: 18px 18px;
      padding-left: calc(20px + #{vr(2)});
    }
  }

  &.-block {
    display: block;
    position: relative;

    &::after {
      background-color: rgba(palette(gray, dark), .4);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      bottom: 0;
      content: '';
      display: block;
      height: 4rem;
      left: 0;
      position: absolute;
      transition: $transition--basic;
      width: 4rem;
      z-index: 2;
    }

    &.-image::after {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/icon-magnify-white.svg);
      background-size: 25px 25px;
    }

    &.-slideshow::after {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/slideshow-white.svg);
      background-size: 25px 22px;
    }

    &.-video::after {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/video-play-white.svg);
      background-size: 25px 25px;
    }

    &.-launch::after {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/launch-white.svg);
      background-size: 25px 25px;
    }

    &.-hover {
      &::after {
        background-color: rgba(palette(gray, dark), .9);
      }
    }
  }
}

.modal-trigger-caption {
  @include smooth-text;
  @include font-14;
  background-color: rgba(palette(gray, dark), .4);
  bottom: 0;
  color: #fff;
  font-weight: $font-weight--normal;
  left: 40px;
  line-height: 40px;
  opacity: 0;
  overflow: hidden;
  padding: 0 .5rem;
  position: absolute;
  text-indent: -100%;
  text-overflow: ellipsis;
  transition: $transition--basic;
  white-space: nowrap;
  width: calc(100% - 40px);

  .-hover & {
    background-color: rgba(palette(gray, dark), .9);
    opacity: 1;
    text-indent: 0;
  }
}


/*
  --------------------
  UI block and container
  --------------------
*/

.modal-container {
  background-color: rgba(#fff, 0);
  height: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  transition:
    background $timing-base $easing-base,
    height 0s linear $timing-base,
    overflow 0s linear 0s,
    visibility 0s linear $timing-base,
    width 0s linear $timing-base;
  visibility: hidden;
  width: 0;
  z-index: $zindex--modal;

  &.-modal-open {
    background-color: rgba(#fff, .95);
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    transition:
      background $timing-base $easing-base,
      height 0s linear 0s,
      overflow 0s linear $timing-base,
      visibility 0s linear 0s,
      width 0s linear 0s;
    visibility: visible;
    width: 100%;
  }
}


/*
  --------------------
  Content container
  --------------------
*/

.modal-content {
  margin: 0 auto;
  opacity: 0;
  padding-top: vr(12);
  transition: opacity ($timing-base * 2) $easing-base;
  width: calc(100% - #{$layout-side-padding});

  .-modal-open & {
    opacity: 1;
    transition: opacity ($timing-base * 2) $easing-base;
  }

  @include respond-to('medium') {
    padding-top: vr(10);
    width: calc(100% - #{vr(20)});
  }

  .image-block,
  .responsive-video {
    margin: 0;
  }

  .slideshow {
    margin-top: 0;
  }

  .slideshow-caption-container {
    margin-bottom: 0;
  }
}


/*
  --------------------
  Close buttons
  --------------------
*/

.modal-close {
  @include hide-text;
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: none;
  height: vr(7);
  position: absolute;
  right: vr(2.5);
  top: vr(2.5);
  transition: $transition--basic;
  width: vr(7);

  .-modal-open & {
    display: block;
  }

  &::before,
  &::after {
    background-color: palette(gray, dark);
    content: '';
    display: block;
    height: vr(5);
    left: 50%;
    margin: vr(-2.5) 0 0 -1px;
    position: absolute;
    top: 50%;
    transform-origin: center center;
    transition: $transition--basic;
    width: 2px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @include hover {
    background-color: palette(blue, x-light);

    &::before,
    &::after {
      background-color: palette(blue);
    }
  }

  [data-whatinput="keyboard"] &:focus {
    box-shadow: 0 0 10px 6px darken(palette(orange), 5);
  }
}

.modal-close-alt {
  display: none;

  button {
    @include standard-hover;
    @include font-13;
    appearance: none;
    border: none;
    color: palette(blue);
    display: inline-block;;
    font-weight: $font-weight--bold;
    margin: 0;
    padding: 0 vr(1);
    text-transform: uppercase;
    transition: $transition--basic;

    @include hover {
      background-color: palette(blue, x-light);
      text-decoration: none;
    }

    [data-whatinput="keyboard"] &:focus {
      box-shadow: 0 0 10px 6px darken(palette(orange), 5);
    }
  }

  .-modal-open & {
    display: block;
  }
}
