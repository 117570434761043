@mixin layout-container-padding($class) {
  + .layout-container:not(.#{$class}) {
    padding-top: vr(7);

    @include respond-to('medium') {
      padding: vr(10) $layout-side-padding;
    }

    @include respond-to('large') {
      padding: vr(15) $layout-side-padding;
    }

    &.-padding-reduced {
      padding: vr(6) $layout-side-padding;

      @include respond-to('medium') {
        padding: vr(10) $layout-side-padding;
      }
    }

    &.-padding-reduced-top {
      padding-top: vr(6);

      @include respond-to('medium') {
        padding-top: vr(10);
      }
    }

    &.-padding-reduced-bottom {
      padding-bottom: vr(6);

      @include respond-to('medium') {
        padding-bottom: vr(10);
      }
    }

    &.-padding-small,
    &.-padding-small-top {
      padding-top: vr(4);
    }
  }

  // prevents padding on two similarly-colored containers
  // from doubling up
  + .#{$class} {
    padding-top: 0;

    &:before {
      display: table;
      content: "";
    }
  }
}
