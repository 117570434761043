.story-attribution {
  @include font-16;
  padding-bottom: vr(4);
  position: relative;

  &::after {
    background-color: palette(gray, light);
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 50px;
  }
}

.story-attribution-author {
  display: block;

  @include respond-to('large') {
    // on large screens, authors display on the same line
    // and are separated by a pipe
    display: inline;

    &::after {
      content: ' | ';
    }

    &:last-of-type::after {
      content: '';
    }
  }
}

.story-attribution-date {
  @include font-12;
  display: block;
  margin-top: $vr;

  @include respond-to('large') {
    margin-top: vr(2);
  }
}
