/*
  --------------------
  Main body type
  --------------------
*/

// set base font size to 10px
html {
  font-size: 62.5%;
}

body {
  @include font-base;

  // display system font until custom font is ready
  font-family: $font-stack--base;

  // switch to custom font once it's downloaded and ready
  .wf-active & {
    font-family: $font-stack--full;
  }

  [lang="ja"] & {
    font-family: $font-stack--japanese;
  }

  [lang="ko"] & {
    font-family: $font-stack--korean;
  }
}

button,
input,
select,
textarea {
  font-family: $font-stack--base;

  // switch to custom font once it's downloaded and ready
  .wf-active & {
    font-family: $font-stack--full;
  }

  [lang="ja"] & {
    font-family: $font-stack--japanese;
  }

  [lang="ko"] & {
    font-family: $font-stack--korean;
  }
}

.breadcrumbs {
  @include font-15;
  text-align: center;
  text-transform: uppercase;

  a {
    display: inline-block;
    padding: 0 vr(1);
  }
}

.lede {
  font-size: 1.8rem;
  line-height: 2.6rem;
  margin: vr(5) auto;
  max-width: width('wide');

  @include respond-to('small') {
    line-height: vr(5);
    text-align: center;
  }

  @include respond-to('medium') {
    font-size: 2rem;
    line-height: vr(6);
  }

  @include respond-to('large') {
    font-size: 2.2rem;
  }

  &.-banner {
    @include respond-to('medium') {
      font-size: 2.4rem;
      line-height: vr(6);
    }

    @include respond-to('large') {
      font-size: 3rem;
      line-height: vr(7);
    }
  }
}


/*
  --------------------
  Headings
  --------------------
*/

// h1's _should not_ be used in the wysiwyg area
.heading-h1 {
  @include heading-h1($size: 'main');
  margin-left: auto;
  margin-right: auto;
  max-width: width('xx-wide');

  &.-basic {
    margin-bottom: vr(2);
    text-align: left;

    + p,
    + ol,
    + ul {
      margin-top: 0;
    }

    @include respond-to('small') {
      font-size: 3.6rem;
      line-height: vr(7);
    }

    @include respond-to('medium') {
      font-size: 4.8rem;
      line-height: vr(10);
    }
  }

  &.-banner {
    @include heading-h1($size: 'banner', $base: false);
  }

  &.-feature {
    @include heading-h1($size: 'feature', $base: false);
  }
}

// utility class
.heading-h2,
// class-less wysiwyg heading
.wysiwyg h2 {
  @include heading-h2();

  &.-alternate {
    @include heading-h2($weight: $font-weight--normal, $base: false);
  }

  &.-alternate-light {
    @include heading-h2($weight: $font-weight--light, $base: false);
  }
}

.heading-callout {
  @include heading-base;
  @include font-26;
  font-weight: $font-weight--normal;

  &.-alternate {
    font-weight: $font-weight--bold;
  }
}

// utility class
.heading-h3,
// class-less wysiwyg heading
.wysiwyg h3 {
  @include heading-h3();

  &.-alternate {
    @include heading-h3($uppercase: true, $base: false);
  }

  &.-weight-normal {
    font-weight: $font-weight--normal;
  }
}

// section heading
.heading-section {
  @include heading-h3($uppercase: true);
  margin-bottom: vr(5);
  text-align: center;
}

// utility class
.heading-h4,
// class-less wysiwyg heading
.wysiwyg h4 {
  @include heading-h4();

  &.-alternate {
    font-weight: $font-weight--normal;
  }
}

// utility class
.heading-h5,
// class-less wysiwyg heading
.wysiwyg h5 {
  @include heading-h5();

  &.-alternate {
    font-weight: $font-weight--normal;
  }
}

.wysiwyg h2,
.heading-h2,
.wysiwyg h3,
.heading-h3,
.wysiwyg h4,
.heading-h4,
.wysiwyg h5,
.heading-h5,
.wysiwyg h6,
h5 {
  @include template-element-maxwidth;
  margin-left: auto;
  margin-right: auto;
}

// elements nested inside a heading but styled with heading class
// should only have slim top margin
[class*="heading-"] {
  [class*="heading-"] {
    margin-top: vr(1) !important;
  }
}

.heading-tag {
  display: block;
  font-size: 1.7rem;
  font-weight: $font-weight--bold;
  line-height: vr(4);
  text-transform: uppercase;
}


/*
  --------------------
  Quotes
  --------------------
*/

.quote-block-quote {
  quotes: '\201c' '\201d' '\2018' '\2019';

  [lang="de"] & {
    quotes: '\201e' '\201c' '\201a' '\2018';
  }

  [lang="fr"] & {
    quotes: '\00ab' '\00bb' '\00ab' '\00bb';
  }

  [lang="it"] &,
  [lang="es"] & {
    quotes: '\00ab' '\00bb' '\201c' '\201d';
  }

  [lang="ja"] & {
    quotes: '\300c' '\300d' '\300e' '\300f';
  }

  &::before,
  &::after {
    font-size: 1.2em;
  }

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}

.quote-inline {
  quotes: none;

  &::before,
  &::after {
    display: none;
  }
}

.quote-block {
  font-size: 2.4rem;
  line-height: vr(5);
  margin: vr(5) auto;
  max-width: width('x-wide');

  @include respond-to('medium') {
    font-size: 3.6rem;
    line-height: vr(8);
    margin: vr(7) auto;
  }
}

.quote-block-quote {
  p {
    display: inline;

    + p::before {
      content:"\a\a";
      white-space: pre;
    }
  }
}

.quote-block-attribution {
  align-items: center;
  display: flex;
  margin-bottom: 0;
  max-width: none;
  width: 100%;

  .no-flexbox & {
    @include clearfix;
    display: block;
  }

  img {
    border-radius: 50%;
    height: 4rem;
    margin-right: 1.2rem;
    min-width: 4rem;
    width: 4rem;

    .no-flexbox & {
      float: left;
      margin-top: vr(.75);
    }
  }
}

.quote-block-attribution-text {
  @include font-14;

  .no-flexbox & {
    display: block;
  }
}

.quote-inline {
  display: block;
  font-size: 2.4rem;
  font-weight: $font-weight--bold;
  line-height: vr(6);
  margin-bottom: vr(4);

  @include respond-to('large') {
    font-size: 3.6rem;
    line-height: vr(9);
  }
}

/*
  --------------------
   Ordered list
  --------------------
*/

ol li:before {
  text-align: right;
  margin-left: -4.4rem;
  width: 3.8rem;
}

/*
  --------------------
  Large number list
  --------------------
*/

%big-list {
  margin: vr(10) auto;
  padding: 0;

  li {
    @include clearfix;
    @include no-first-last-margins;

    + li {
      margin-top: vr(8);
    }

    * {
      max-width: 100%;
    }
  }
}

.number-list {
  @extend %big-list;

  li {
    padding-left: 4.4rem;

    @include respond-to('small') {
      padding-left: 13rem;
    }

    @include respond-to('large') {
      padding-left: 0;
    }

    &::before {
      font-size: 2.4rem;
      line-height: vr(5);
      text-align: right;
      margin-left: -4.4rem;
      width: 3.8rem;
      
      @include respond-to('small') {
        @include smooth-text;
        color: palette(red);
        content: counter(list);
        font-size: 10rem;
        font-weight: $font-weight--light;
        height: vr(14);
        line-height: vr(14);
        margin-left: -14rem;
        width: 12rem;
      }

      @include respond-to('large') {
        font-size: 14rem;
        height: vr(18);
        line-height: vr(18);
        margin-left: -19rem;
        width: 17rem;
        text-align: right;
      }
    }
  }
}


/*
  --------------------
  Thumbnail list
  --------------------
*/

.thumbnail-list {
  @extend %big-list;
  @include list-unstyled;

  img {
    display: none;

    @include respond-to('small') {
      display: block;
      float: left;
      height: auto;
      margin-bottom: 0;
      margin-left: -15rem;
      max-width: none;
      width: 13rem;
    }

    @include respond-to('large') {
      margin-left: -15rem;
    }
  }

  li {
    @include respond-to('small') {
      padding-left: 15rem;
    }

    @include respond-to('large') {
      padding-left: 0;
    }
  }
}


/*
  --------------------
  People list
  --------------------
*/

.people-list {
  @extend %big-list;
  @include list-unstyled;

  li {
    border-top: 1px solid palette(gray, light);
    padding-top: vr(10);

    + li {
      margin-top: vr(10);
    }
  }
}

.people-list-image {
  img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;

    @include respond-to('medium') {
      margin: 0;
      max-width: none;
      width: 100%;
    }
  }
}

.people-list-text {
  font-size: 1.6rem;

  > :last-child {
    margin-bottom: 0;
  }

  > * {
    @include respond-to('medium') {
      margin-left: 3rem;
    }
  }
}


/*
  --------------------
  Utilities
  --------------------
*/

// drop caps only work on latin-based languages
@include latin-only {
  .u-drop-cap {
    p:first-of-type:first-letter {
      float: left;
      font-size: vr(10);
      font-weight: $font-weight--bold;
      line-height: vr(10);
      padding-right: $vr;
    }
  }
}
