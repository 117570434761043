.site-nav-container {
  background-color: rgba(#fff, 0);
  height: 0;
  left: 0;
  position: absolute;
  top: $header-height--small;
  transition:
    background $timing-base $easing-base,
    height 0s linear $timing-base;
  width: 100%;
  z-index: 1;

  [data-mobile="navigation"] & {
    background-color: rgba(#fff, 1);
    border-top: 1px solid palette(gray, x-light);
    // height applied in JavaScript
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translate3d(0, 0, 0); // fixes render bug in Chrome
    transition:
      background $timing-base $easing-base,
      height 0s linear 0s;
  }

  @include respond-to('medium-large') {
    background-color: rgba(#fff, 1);
    height: auto;
    padding: -1px $layout-side-padding 0;
    top: $header-height--large;

    .js &,
    [data-nav-is="open"] & {
      box-shadow: 0 0 18px rgba(#000, .3);
    }

    [data-nav-is="close"] & {
      box-shadow: none;
      //border-bottom: 1px solid #E8EBEE;
    }
  }
}

.site-nav {
  @include clearfix;
  opacity: 0;
  transition:
    opacity $timing-base $easing-base,
    visibility 0s linear $timing-base;
  visibility: collapse;
  position: relative;

  [data-mobile="navigation"] & {
    opacity: 1;
    transition:
      opacity $timing-base $easing-base,
      visibility 0s linear 0s;
    visibility: visible;
  }

  @include respond-to('medium-large') {
    opacity: 1;
    overflow: hidden;
    visibility: visible;
    padding: 0 $layout-side-padding;

    // Edge-to-edge line
    &:after {
      width: 100%;
      height: 1px;
      background-color: #E8EBEE;
      position: absolute;
      content: '';
      top: 49px;
      left: 0;
    }
  }
}

.site-nav-list {
  @include list-unstyled;
  margin: 0 auto;
  max-width: none;
  padding: 2rem 0;

  @include respond-to('medium-large') {
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid transparent; // Leave space for edge-to-edge line (.site-nav:after)
    height: 50px;
    max-width: $header-container-max-width-desktop;
  }

  a {
    color: palette(gray);
    display: block;

    @include hover {
      background-color: transparent;
      color: darken(palette(blue), 2);
    }
  }
}

.site-nav-item {
  margin: 0;
  text-align: center;

  @include respond-to('medium-large') {
    float: left;
    padding-right: 4rem;
    position: relative;
    text-align: left;

    // 5 columns of links
    &:nth-last-child(5):first-child,
    &:nth-last-child(5):first-child ~ .site-nav-item {
      width: percentage(1/5);
    }

    // 6 columns of links
    &:nth-last-child(6):first-child,
    &:nth-last-child(6):first-child ~ .site-nav-item {
      width: percentage(1/6);
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.site-nav-item-heading {
  font-size: 1.8rem;
  margin: 1.4rem 0;

  @include respond-to('medium-large') {
    @include font-13;
    margin: 0;
  }

  @include respond-to('large') {
    @include font-15;
  }

  a {
    display: inline-block;
    position: relative;

    @include respond-to('medium-large') {
      font-weight: $font-weight--normal;
    }

    &:focus {
      color: palette(gray);
    }

    &::after {
      background-color: palette(gray, x-light);
      content: '';
      display: block;
      height: 4px;
      left: 50%;
      margin-left: -50%;
      position: absolute;
      top: 100%;
      transform: scaleX(0);
      transition: transform $timing-base $easing-base;
      width: 100%;
    }

    &[aria-expanded="true"]::after {
      transform: scaleX(1);
    }

    @include respond-to('medium-large') {
      display: block;
      line-height: $nav-height--large;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      [data-whatinput="touch"] & {
        color: palette(gray);
      }
    }
  }

  .site-nav-a11y-helper {
    display: none;

    @include respond-to('medium-large') {
      @include a11y--visually-hide;
      display: inline;
    }
  }
}

.site-nav-sublist {
  margin: 0;
  max-width: none;
  overflow: hidden;

  &[data-is="close"] {
    max-height: 0;
    opacity: 0;
    transition:
      max-height $timing-base ease-out,
      opacity $timing-base ease-out,
      visibility 0s linear $timing-base;
    visibility: hidden;
  }

  &[data-is="open"] {
    max-height: 60rem;
    opacity: 1;
    transition:
      max-height $timing-base ease-in,
      opacity $timing-base ease-in,
      visibility 0s linear 0s;
    visibility: visible;
  }

  a {
    display: inline-block;
    font-size: 1.6rem;
    font-weight: $font-weight--normal;
    padding: .8rem 0;

    @include respond-to('medium-large') {
      display: block;
      font-size: 1.4rem;
      line-height: 2rem;

      [data-whatinput="keyboard"] &:focus {
        background-color: palette(blue, x-light);
        outline: none;
      }
    }
  }

  @include respond-to('medium-large') {
    overflow: visible;

    .js &,
    [data-nav-is="close"] & {
      max-height: 0;
      opacity: 0;
      transition:
        max-height $timing-base ease-out $timing-delay,
        opacity $timing-base ease-out $timing-delay,
        visibility 0s linear ($timing-base + $timing-delay);
      visibility: hidden;
    }

    [data-nav-is="open"] & {
      max-height: 60rem;
      opacity: 1;
      transition:
        max-height $timing-base ease-in $timing-delay,
        opacity $timing-base ease-in $timing-delay,
        visibility 0s linear $timing-delay;
      visibility: visible;
    }
  }
}

// arrow hover interaction for top and sub-level links
.site-nav-item-heading,
.site-nav-sublist a {
  @include respond-to('medium-large') {
    position: relative;

    &::after {
      background: url(../images/arrow-down-blue.svg) no-repeat 50% 50%;
      background-size: 10px 6px;
      content: '';
      display: block;
      height: 6px;
      left: -1.8rem;
      margin-top: -3px;
      opacity: 0;
      position: absolute;
      top: 50%;
      transform: rotate(-90deg);
      transition: $transition--basic $timing-delay;
      width: 10px;

      [data-whatinput="touch"] & {
        display: none;
      }
    }

    @include hover {
      &::after {
        opacity: 1;
        left: -1.5rem;
      }
    }
  }
}

// close indicator for touch
@include respond-to('medium-large') {
  [data-whatinput="touch"] [data-is="open"] .site-nav-item-heading a {
    overflow: visible;
    position: relative;

    &::after {
      background-color: transparent;
      content: '×';
      color: palette(red, error);
      display: block;
      font-size: 2rem;
      left: auto;
      line-height: 2rem;
      margin: vr(-1.5) 0 0;
      position: absolute;
      right: -1rem;
      text-align: center;
      top: 50%;
      width: 2rem;
    }
  }
}

.site-nav-subitem {
  margin: 0;
  position: relative;

  @include respond-to('medium-large') {
    opacity: 0;
    transition: ($timing-base / 2) $easing-base $timing-delay;

    [data-nav-is="open"] & {
      opacity: 1;
      transition: ($timing-base / 2) $easing-base;
    }

    &:last-child {
      padding-bottom: vr(6);
    }
  }

  // featured article
  &.-article {
    @include respond-to('medium-large') {
      margin-top: vr(2);

      // don't use arrow hover indicator
      a::after {
        display: none;
      }
    }

    a {
      @include hover {
        img {
          box-shadow: 0 0 8px rgba(#000, 0.2);
        }
      }
    }

    img {
      height: auto;
      max-width: 100%;
      transition: $transition--basic;
    }

    .site-nav-article-date {
      // date stays gray, even on hover
      color: palette(gray, dark);
    }
  }
}

.site-nav-article-heading {
  @include font-14;
  margin: 0;
}

.site-nav-article-date {
  @include font-12;
}
