/*
  --------------------
  Article grid
  --------------------
*/

.article-grid {
  @include clearfix;
  margin: vr(10) auto;

  @include respond-to('small') {
    margin-bottom: vr(6);
    margin-top: vr(6);
  }
}

.article-grid-list {
  @include list-unstyled;
  margin: 0;
  max-width: none;

  @include respond-to('small') {
    margin-left: vr(-4);
  }

  li {
    margin: 0;
    padding: vr(2) 0;

    @include respond-to('small') {
      padding: vr(2) 0 vr(2) vr(4);
    }
  }
}

.article-grid-item {
  @include respond-to('small') {
    float: left;
    width: percentage(1/2);
  }

  @include respond-to('medium') {
    width: percentage(1/3);
  }

  a {
    background-color: #fff;
    border-style: solid;
    border-width: 2px 0 0;
    color: palette(gray, dark);
    display: block;
    font-weight: $font-weight--normal;
    height: 100%;
    overflow: hidden;
    padding: vr(5) vr(5) vr(4);
    transition: $transition--basic;

    @include hover {
      box-shadow: $card-shadow;
      transform: $card-lift;
    }
  }

  &.-last {
    @include respond-to('small') {
      float: right;
    }
  }

  &.-story {
    a {
      > * {
        display: block;
      }
    }
  }

  &.-feature {
    a {
      @include smooth-text;
      align-items: center;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 3rem vr(12);
      position: relative;
      text-align: center;

      &::before {
        background: linear-gradient(
          to bottom,
          rgba(#000, 0) 0,
          rgba(#000, 1) 100%
        );
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: .8;
        position: absolute;
        top: 30%;
        transition: $transition--basic;
        width: 100%;
        z-index: 1;
      }

      * {
        position: relative;
        z-index: 2;
      }
    }
  }

  &.-news {
    a {
      border-color: palette(green);
    }

    .article-grid-tag {
      color: palette(green);
    }
  }

  &.-press {
    a {
      border-color: palette(purple);
    }

    .article-grid-tag {
      color: palette(purple);
    }
  }
}

.article-grid-tag {
  @include font-12;
  display: block;
  font-weight: $font-weight--bold;
  text-transform: uppercase;
  transition: $transition--basic;

  .-feature a & {
    background-color: palette(gray, dark);
    bottom: 0;
    color: #fff;
    left: 0;
    line-height: vr(8);
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;
  }
}

.article-grid-title {
  @include font-24;
  font-weight: $font-weight--normal;

  .-feature & {
    @include font-32;
    font-weight: $font-weight--light;
    margin: 0;
    max-width: 100%;
    transition: $transition--basic;
  }
}

.article-grid-date {
  @include font-14;
  color: lighten(palette(gray, dark), 22);
}

.article-grid-published {
  @include font-14;
  margin: vr(1) 0 0;

  .-feature & {
    transition: $transition--basic;
  }
}


/*
  --------------------
  Article filters
  --------------------
*/

.article-filters {
  margin-top: vr(10);

  + .article-grid {
    margin-top: vr(4);
  }
}

.article-filters-search {
  position: relative;

  input[type="text"],
  input[type="search"] {
    border-right: none;
    width: calc(100% - #{vr(7)});
  }

  button,
  input[type="submit"] {
    @include hide-text;
    appearance: none;
    background: palette(blue) url(/sites/all/themes/rotary_rotaryorg/images/icon-magnify-white.svg) no-repeat 50% 50%;
    background-size: 18px 18px;
    border: none;
    border-radius: 0;
    height: vr(7);
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: $transition--basic;
    width: vr(7);

    @include hover {
      background-color: darken(palette(blue), 4);
    }
  }
}
