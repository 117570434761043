@mixin pull($n, $width) {
  // pulled elements fall on a 1/2 grid distance
  // but should be referenced on the whole grid for consistency
  $pull: $n * 100;

  @media (min-width: pull-threshold($pull, $width)) {
    margin-bottom: vr(2);
    margin-top: 0;

    // slideshows and images get a small amount of margin
    // for alignment with text
    &.image-block,
    &.slideshow,
    &.u-container-bordered {
      margin-top: vr(2);
    }

    &.u-pull-left {
      float: left;
      margin-left: px-to-rem($pull * -1);
      margin-right: vr(4);
    }

    &.u-pull-right {
      float: right;
      margin-left: vr(4);
      margin-right: px-to-rem($pull * -1);
    }
  }
}
