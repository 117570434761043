.link-dropdown {
  background-color: #fff;
  position: relative;

  p + & {
    margin-top: vr(-2);
  }
}

.link-dropdown-toggle {
  @include font-base;
  appearance: none;
  background-color: #fff;
  border: none;
  color: palette(blue);
  font-weight: $font-weight--bold;
  height: vr(10);
  padding: 0 2rem;
  position: relative;
  text-align: left;
  transition: color $timing-base $easing-base;
  width: 100%;
  z-index: ($zindex--modal + 1);

  &::after {
    background: url(/sites/all/themes/rotary_rotaryorg/images/arrow-down-blue.svg) no-repeat 50% 50%;
    background-size: 14px 8px;
    content: '';
    display: block;
    height: 8px;
    margin-top: -4px;
    position: absolute;
    right: 20px;
    top: 50%;
    width: 14px;
  }

  .site-footer & {
    @include font-16;
    color: palette(gray);
    height: vr(8);
    text-align: center;

    &::after {
      display: none;
    }
  }

  [data-is="open"] & {
    color: palette(gray, light);

    &::before {
      background-color: palette(gray, light);
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &::after {
      transform: rotate(180deg);
    }
  }

  [data-features="position-up"][data-is="open"] & {
    &::before {
      bottom: auto;
      top: 0;
    }
  }
}

.link-dropdown-list {
  @include list-unstyled;
  left: 0;
  margin: 0;
  position: absolute;
  padding-top: vr(10);
  top: 0;
  transform: translateY(vr(-1));
  transition:
    opacity $timing-base $easing-base,
    transform ($timing-base / 2) $easing-base,
    visibility 0s linear $timing-base;
  width: 100%;
  z-index: $zindex--modal;

  .js & {
    opacity: 0;
    visibility: hidden;
  }

  .site-footer & {
    padding-top: vr(8);
  }

  [data-features="position-up"] & {
    bottom: 0;
    padding-top: 0;
    padding-bottom: vr(10);
    top: auto;
    transform: translateY(vr(1));

    .site-footer & {
      padding-bottom: vr(8);
    }
  }

  [data-is="open"] & {
    box-shadow: 0 3px 16px rgba(#000, .15);
    opacity: 1;
    transform: translateY(0);
    transition:
      opacity $timing-base $easing-base,
      transform ($timing-base / 2) $easing-base,
      visibility 0s linear 0s;
    visibility: visible;
  }
}

.link-dropdown-item {
  margin: 0;

  a {
    background-color: #fff;
    color: palette(gray, dark);
    display: block;
    font-weight: $font-weight--normal;
    padding: vr(1.5) 2rem;

    @include hover {
      background-color: palette(gray, xx-light);
      color: palette(blue);
    }
  }
}
