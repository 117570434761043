.share {
  @include font-12;
  align-items: center;
  color: palette(gray);
  display: flex;
  font-weight: $font-weight--normal;
  line-height: 2.4rem;
  text-transform: uppercase;

  &.-share-big,
  figcaption & {
    justify-content: center;
  }

  .story-attribution & {
    margin-bottom: vr(4);

    @include respond-to('small') {
      float: right;
      margin: 0;
    }
  }
}

.share-link {
  display: block;
  height: 2.4rem;
  margin-left: 1.4rem;
  position: relative;
  text-align: center;
  width: 2.4rem;

  .no-cssfilters & {
    // give proper background contrast to browsers
    // that don't support css filters
    background-color: #fff;
  }

  img {
    height: 23px;
    filter:
      // reset the color to base gray
      grayscale(100%)
      contrast(0)

      // tweak the color
      brightness(1.2)
      sepia(20%)
      hue-rotate(150deg);
    transition: $transition--basic;
    width: auto;
  }

  @include hover {
    background-color: transparent;

    img {
      filter: unset;
    }
  }

  .-share-big & {
    border: 2px solid #fff;
    border-radius: 50%;
    height: 6rem;
    margin: 0;
    position: relative;
    width: 6rem;

    + .share-link {
      margin-left: 2.6rem;
    }

    img {
      height: 30px;
      filter:
        // reset the color to base gray
        grayscale(100%)
        contrast(0)

        // tweak the color
        brightness(2);
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: auto;
    }

    @include hover {
      background-color: #fff;

      img {
        filter: unset;
      }
    }
  }
}
