/*
  --------------------
  General text styles
  Note: Forcing Immutability with !important for utility classes: http://csswizardry.com/2016/05/the-importance-of-important/#forcing-immutability-with-important
  --------------------
*/

.u-text-centered {
  text-align: center !important;
}

.u-text-left {
  text-align: left !important;
}

.u-text-right {
  text-align: right !important;
}

.u-list-unstyled {
  @include list-unstyled;
}


/*
  --------------------
  General container styles
  Note: Forcing Immutability with !important for utility classes: http://csswizardry.com/2016/05/the-importance-of-important/#forcing-immutability-with-important
  --------------------
*/

.u-centered {
  margin-left: auto !important;
  margin-right: auto !important;
}


/*
  --------------------
  Other general structural utility styles
  --------------------
*/

.u-nowrap {
  white-space: nowrap !important;
}


/*
  --------------------
  Colors
  Note: Forcing Immutability with !important for utility classes: http://csswizardry.com/2016/05/the-importance-of-important/#forcing-immutability-with-important
  --------------------
*/


// blue
.u-color-blue {
  color: palette(blue) !important;
}

.u-background-blue {
  background-color: palette(blue) !important;
}

.u-border-blue {
  border-color: palette(blue) !important;
}

// dark blue
.u-color-darkblue {
  color: palette(blue, xx-dark) !important;
}

.u-background-darkblue {
  background-color: palette(blue, xx-dark) !important;
}

.u-border-darkblue {
  border-color: palette(blue, xx-dark) !important;
}

// royal blue
.u-background-royalblue {
  background-color: palette(blue, royal) !important;
}

// green
.u-color-green {
  color: palette(green) !important;
}

.u-background-green {
  background-color: palette(green) !important;
}

.u-border-green {
  border-color: palette(green) !important;
}

// purple
.u-color-purple {
  color: palette(purple) !important;
}

.u-background-purple {
  background-color: palette(purple) !important;
}

.u-border-purple {
  border-color: palette(purple) !important;
}

// red
.u-color-red {
  color: palette(red) !important;
}

.u-background-red {
  background-color: palette(red) !important;
}

.u-border-red {
  border-color: palette(red) !important;
}


/*
  --------------------
  Patterns
  --------------------
*/

.u-background-triangles {
  @include background-triangles;

  &.u-background-blue {
    background-image: url(../images/geo-blue.png);
  }

  &.u-background-darkblue {
    background-image: url(../images/geo-darkblue.png);
  }

  &.u-background-green {
    background-image: url(../images/geo-green.png);
  }

  &.u-background-purple {
    background-image: url(../images/geo-purple.png);
  }

  &.u-background-red {
    background-image: url(../images/geo-red.png);
  }
}


/*
  --------------------
  Generic container to be
  paired with widths
  --------------------
*/

.u-container {
  @include no-first-last-margins;
  margin: 0 auto;
  position: relative;
  z-index: $zindex--layout-container;

  p,
  ol,
  ul {
    max-width: none;
  }

  & + .u-container {
    margin-top: vr(10);
  }
}

&.u-container-bordered {
  @include no-first-last-margins;
  border: 1px solid palette(gray, light);
  padding: vr(4);
}


/*
  --------------------
  Widths
  --------------------
*/

// content widths
@for $i from 1 through 12 {
  .u-width-#{$i} {
    max-width: px-to-rem($i * 100);

    // widths up to 4 columns that are floated left or right
    // get an extra 1/2 column of width
    @if $i < 5 {
      &.u-pull-left,
      &.u-pull-right {
        max-width: px-to-rem(($i * 100) + 50);
      }
    }
  }
}

// allows a `u-container` inside a `layout-container` to be edge-to-edge width
.u-width-full {
  margin-left: $layout-side-padding * -1;
  width: calc(100% + #{($layout-side-padding * 2)});
}


/*
  --------------------
  Pulled content
  --------------------
*/

// pulled contetnt wrapper
.u-pull-container {
  @include template-element-maxwidth;
  margin: 0 auto;

  &.-sidebar {
    max-width: width('xxxx-wide');
  }

  // Compatibility with FitVids.js. Ensure that a floated container
  // hits its max-width, so that FitVids' style has something to fill.
  > div {
    &:before {
      display: block;
      width: 2000px;
      content: "";
    }
  }
}

// pull directions are:
// `u-pull-left`
// `u-pull-right`

// distance to pull item out of content column
// `0` is no pull
.u-pull-0 {
  @include respond-to('small') {
    margin-bottom: vr(2);
    margin-top: 0;

    &.image-block,
    &.slideshow,
    &.u-container-bordered {
      margin-top: vr(2);
    }

    &.u-pull-left {
      float: left;
      margin-right: vr(4);
    }

    &.u-pull-right {
      float: right;
      margin-left: vr(4);
    }
  }
}

@for $i from 1 through 4 {
  .u-pull-#{$i} {
    @include pull($i, 'wide');
    margin-left: auto;
    margin-right: auto;

    .template-story & {
      @include pull($i, 'regular');
    }
  }
}


/*
  --------------------
  Sidebar
  --------------------
*/

.u-sidebar {
  display: none;

  @include respond-to('small') {
    @include font-14;
    @include no-first-last-margins;
    display: block;
    float: right;
    margin: 0 auto vr(2) ($layout-side-padding * 2);
    width: 20rem;
  }

  a {
    color: palette(purple);
    font-weight: $font-weight--normal;

    @include hover {
      @include standard-hover;
    }
  }

  hr {
    margin: vr(2) 0;
  }

  p {
    margin: vr(1) 0;
  }
}

// Rule to hide any element.
.u-display-none {
  display: none !important;
}
