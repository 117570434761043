@mixin dotted-line($color, $dir: vertical) {
  $to: bottom;

  @if $dir == horizontal {
    $to: right;
  }

  background: repeating-linear-gradient(
    to $to,
    $color,
    $color 2px,
    transparent 2px,
    transparent 6px
  );
}
