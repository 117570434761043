.stat-background-img {
  background-attachment: fixed;
  @include respond-to('medium-large') {
    &[data-module="in-view"] {
        opacity: 0;
        transform: translateY(#{vr(3)});
        transition: all ($timing-base * 2) $easing-base;
        @supports (-moz-appearance: none) {
          transform: none;
        }

    }

    &[data-is="visible"] {
        opacity: 1;
        transform: translateY(0);
        @supports (-moz-appearance: none) {
          transform: none;
        }
    }
  }
}

.impact-stat-list .fadeInBottom {
  opacity: 0;
  transition:
  opacity ($timing-base * 2) $easing-base,
  transform ($timing-base * 2) $easing-base;
}

.impact-stat-list .fadeOutBottom {
  opacity: 1;
  transform: translateY(0);
 }

.text-stat {
  @include clearfix;
  margin: vr(10) auto;
}

// colors
div#statset, #statset-description {
  color: palette(green);
}

div#statset--2, #statset-description--2 {
  color: palette(purple);
}

div#statset--3, #statset-description--3 {
   color: palette(orange, dark),
}

// Css for each media query
.impact-stat-list {
@include respond-to('medium') {
  flex-direction: column;
}
@include respond-to('medium-large') {
  @include list-unstyled;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: unset;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: inline;
}
}

.title-info {
@include respond-to('extra-small') {
  display: flex;
  padding: 5% 5%;
}
@include respond-to('small') {
  display: flex;
  padding: 2% 2%;
}
@include respond-to('medium-large') {
  display: flex;
  padding: 0 5%;
}
}

.stat-suffix {
@include respond-to('extra-small') {
  font-size: 2.30rem;
  line-height: 0;
}
@include respond-to('small') {
  font-size: 2.625rem;
  line-height: 0;
}
@include respond-to('medium-large') {
  font-size: 2.625rem;
  line-height: 0;
}
}

.title-text {
@include respond-to('extra-small') {
  flex-basis: 60%;
  padding-bottom: 2%;
}
@include respond-to('small') {
  flex-basis: 55%;
  padding-bottom: 2%;
}
@include respond-to('medium-large') {
  flex-basis: 70%;
  padding-bottom: 8%;
}
@include respond-to('large') {
  flex-basis: 60%;
  padding-bottom: 8%;
}
text-align: right;
}

.title-img {
@include respond-to('extra-small') {
  flex-basis: 40%;
  height: 7rem;
  width: 7rem;
  display: inline-block;
}
@include respond-to('small') {
  flex-basis: 45%;
}
@include respond-to('medium-large') {
  flex-basis: 30%;
  height: 10rem;
  width: 10rem;
  display: inline-block;
}
@include respond-to('large') {
  flex-basis: 40%;
}
text-align: left;
}

img.stat-icon {
@include respond-to('extra-small') {
  width: 8rem;
  padding: 12px 19px 7px 0;
  height: 9rem;
}
@include respond-to('small') {
  width: 7.7rem;
  padding: 10px 17px 12px 0;
  height: 9rem;
}
@include respond-to('medium') {
  width: 8rem;
  padding: 2px 3px 8px 1px;
  height: 9.7rem;
}
@include respond-to('medium-large') {
  width: 7.5rem;
  padding: 1px 2px 3px 0px;
  height: 9rem;
}
@include respond-to('large') {
  width: 7.7rem;
  padding: 5px 8px 12px 0px;
  height: 10rem;
}
}

.title-text .impact-stat-number {
@include respond-to('extra-small') {
  font-size: 5.1rem;
}
@include respond-to('small') {
  font-size: 5.2rem;
}
@include respond-to('medium') {
  font-size: 5.2rem;
}
@include respond-to('medium-large') {
  font-size: 5.1rem;
}
}

strong.impact-stat-number span {
@include respond-to('extra-small') {
  margin-right: 0.1rem;
}
@include respond-to('small') {
  margin-right: 0.40rem;
}
}

.text-stat-title {
@include respond-to('extra-small') {
  font-size: 1.5rem;
}
@include respond-to('small') {
  font-size: 1.5rem;
}
@include respond-to('medium') {
  font-size: 1.6rem;
}
@include respond-to('medium-large') {
  font-size: 1.5rem;
}
@include respond-to('large') {
  font-size: 1.6rem;
}
font-style: normal;
font-weight: bold;
line-height: 2.20rem;
text-align: center;
letter-spacing: 0.01em;
text-transform: uppercase;
margin-bottom: 0;
}

// Css for all media queries.
.stat-set {
padding: 0 1%;
margin: 0 3% 2.5rem;
flex-basis: 33.33%;

@include respond-to('medium-large') {
  padding: 0 1%;
  margin: 0 1%;
  flex-basis: 32.5%;
  max-width: 32.5%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width:31%;
  }
}
@include respond-to('large') {
  padding: 0 1%;
  margin: 0 3% 1.2rem;
  flex-basis: 33.33%;
  max-width: 33.33%;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width:26.5%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    float:left;
}
}

.stat-suffix {
font-style: normal;
font-weight: bold;
text-align: center;
letter-spacing: 0.01em;
white-space: nowrap;
}

.impact-stat-description {
font-style: normal;
font-weight: normal;
font-size: 1.8px;
line-height:2.5rem;;
text-align: center;
letter-spacing: 0.01em;
overflow-y: hidden;

/* Rotary Text Colors/Dark Gray */
color: #39424A;

@include respond-to('extra-small') {
  font-size: 1.7rem;
}
@include respond-to('small') {
  font-size: 1.7rem;
}
@include respond-to('medium') {
  font-size: 1.7rem;
}
@include respond-to('medium-large') {
  font-size: 1.7rem;
}
@include respond-to('large') {
  font-size: 1.7rem;
}
}

.impact-stat-description p {
margin-top: 0.8rem;
margin-left: 2.5rem;
margin-right: 2.5rem;
@include respond-to('large') {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
}

.stat-wrapper-description {
padding: 0.1rem 4% 1rem;
width: 100%;
background: #FFFFFF;
@include respond-to('large') {
  margin-left: 2rem;
  width: 95%;
}
}
.title-text .impact-stat-number{
font-style: normal;
font-weight: bold;
font-size: 4.5rem;
line-height: 6.13rem;
text-align: right;
letter-spacing: 0.01em;

}

.impact-stat-number{
margin-bottom: 0;
margin-top: 0;
display: block;
white-space: nowrap;
}

// Animation for background image only for desktop.
@include respond-to('medium-large') {
  .fade-in {
    opacity: 0;
    transition: opacity 250ms ease-in;
  }

  .fade-in.appear {
    opacity: 1;
  }

  .from-left {
    grid-column: 2 / 3;
    transform: translateX(-50%);
  }

  .from-left{
    transition: opacity 250ms ease-in, transform 400ms ease-in;
    opacity: 0;
  }

  .from-left.appear {
    transform: translateX(0);
    opacity: 1;
  }
}

.impact-stat-set {
@include respond-to('extra-small') {
  [data-animate=fade-up][data-is=ready] {
    opacity: 1;
    transform: translateY(0);
  }
  [data-animate=fade-up] {
    transition: none;
  }
}

@include respond-to('small') {
  [data-animate=fade-up][data-is=ready] {
    opacity: 1;
    transform: translateY(0);
  }
  [data-animate=fade-up] {
    transition: none;
  }
}

@include respond-to('medium') {
  [data-animate=fade-up][data-is=ready] {
    opacity: 1;
    transform: translateY(0);
  }
  [data-animate=fade-up] {
    transition: none;
  }
}
}

.custom-u-width {
@include respond-to('medium-large') {
  width: 100%;
  margin: 0 auto;
}
@include respond-to('large') {
  width: 95%;
  margin: 0 auto;
}
width: 100%;
margin: 0 auto;
}
