.cause-navigation {
  background-color: #000;
  position: relative;
  z-index: $zindex--layout-container;
}

.cause-navigation-list {
  @include list-unstyled;
  margin: vr(-4) 0 0;
  max-width: none;
  padding-bottom: vr(8);
  position: relative;
  text-align: center;
  z-index: 2;

  @include respond-to('medium') {
    margin-top: vr(-6);
    padding-bottom: vr(11);
  }

  @include respond-to('medium-large') {
    display: flex;
    margin-top: 0;
    max-width: none;
    padding-bottom: 0;
    width: 100%;
  }
}

.cause-navigation-item {
  + .cause-navigation-item {
    margin-top: vr(2);

    @include respond-to('medium-large') {
      margin: 0;
    }
  }

  @include respond-to('medium-large') {
    flex-basis: 0;
    flex-grow: 1;
    height: 35vh;
    min-height: 30rem;
    overflow: hidden;
    position: relative;

    &:hover {
      .cause-navigation-image {
        transform: scale(1.1) translate3d(0, 0, 0);
      }
    }
  }

  a {
    font-weight: $font-weight--normal;

    @include respond-to('medium-large') {
      align-items: center;
      color: #fff;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: vr(2);
      position: relative;
      text-shadow: 0 0 4px rgba(#000, .45);
      z-index: 2;
    }

    @include hover {
      background-color: rgba(#000, .7);
      text-decoration: underline;

      @include respond-to('medium-large') {
        text-decoration: none;
      }
    }
  }
}

.cause-navigation-image {
  @include respond-to('medium-large') {
    background-color: #000;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: transform $timing-base $easing-base;
    transform: translate3d(0, 0, 0);
    width: 100%;
    z-index: 1;
  }
}
/* Rotary Homepage Css for Causes section */
.home-causes-image {
  display: none;
  @include respond-to('medium-large') {
    appearance: none;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    display: block;
    font-size: 22px;
    height: calc(100% - 10px);
    left: 5px;
    margin: 0;
    top: 0;
    transition: all $timing-base $easing-base;
    width: calc(100% - 10px);
    z-index: 10;
    border-radius: 50%;
  }
  @include respond-to('large') {
    appearance: none;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    display: block;
    font-size: 22px;
    height: calc(100% - 10px);
    left: 5px;
    margin: 0;
    top: 0;
    transition: all $timing-base $easing-base;
    width: calc(100% - 10px);
    z-index: 10;
    border-radius: 50%;
  }
  @include respond-to('small') {
    appearance: none;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    display: block;
    font-size: 22px;
    height: 100%;
    min-height: 24.8rem;
    margin: 0 9%;
    top: 0;
    transition: all $timing-base $easing-base;
    width: 82%;
    padding-top: 82%;
    z-index: 10;
    border-radius: 50%;
  }

  @include respond-to('medium') {
    appearance: none;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    display: block;
    font-size: 22px;
    height: 100%;
    min-height: 290px;
    margin: 0 8%;
    top: 0;
    transition: all $timing-base $easing-base;
    width: 83%;
    z-index: 10;
    border-radius: 50%;
  }
}
.home-causes-list {
  @include respond-to('medium-large') {
    @include clearfix;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    width: calc(100% + 10px);
  }
}
.home-causes-item-list {
  @include respond-to('medium-large') {
    padding: 0 25px 65px;
    position: relative;
    width: calc(#{percentage(1/3)});
    // pseudo element to hold a square space
    &::before {
      content: '';
      display: block;
      padding-bottom: 85%;
      height: 0;
      width: 100%;
    }

    &[data-hidden] {
      opacity: 0;
      visibility: hidden;
    }

    &.doppelganger {
      transition:
        top $timing-base $easing-base,
        left $timing-base $easing-base,
        width $timing-base $easing-base;
      position: fixed;
      z-index: 1000;
    }
  }

  @include respond-to('medium') {
    padding: 0 13px 6px;
    position: relative;
    width: calc(#{percentage(1/2)});
    float: left;
    list-style-type: none;
    margin: 0;
    overflow: hidden;

    // pseudo element to hold a square space
    &::before {
      content: '';
      display: block;
      padding-bottom: 85%;
      height: 0;
      width: 100%;
    }
  }
}
li#cause--4, li#cause--5, li#cause--6 {
  @include respond-to('medium-large') {
  }
}

.causes-link div {
  @include respond-to('medium-large') {
  padding: 11px 0px 10px 12px;
  width: 87.333333%;
  top: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  text-align: center;
  }
  @include respond-to('extra-small') {
    padding: 12px 0 25px;
    height: 75px;
    width: 92%;
    margin: 0 4%;
    top: 0px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    text-align: center;
  }
}

.causes-text {
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  /* identical to box height */
  display: block;
  text-align: center;
  letter-spacing: -0.03em;

  /* Rotary Neutral Colors/White */

  color: #FFFFFF;
}

.causes-description {
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 30px;
  /* or 150% */
  display: block;
  text-align: center;

  /* Rotary Neutral Colors/White */

  color: #FFFFFF;
}

/* Desktop View Css */
.desktop-view {
  display: none;
}
.desktop-view {
  @include respond-to('medium-large') {
    display: block;
  }
  @include respond-to('large') {
    display: block;
  }
}

  .desktop-view {
    .home-causes-lists {
      width: 100%;
      margin: 0 auto;
      display: flex;
    }
    .aof {
      flex: 33.33%;
    }
    .aof-list {
      padding: 0 2%;
      margin: 12% 0;
    }
    .aof-middle {
      padding: 0 2%;
    }
    .home-causes-image {
      height: 28rem !important;
      min-height: 0px !important;
    }
    .home-causes-image:hover {
      transform: scale(1.1);
    }
  }

/* Mobile View Css for Potrait and landscape mode */
.mobile-view {
  display: none;

  .AOF-mobile-heading {
    padding: 1.8rem 1.8rem 5rem 1.8rem;
    position: relative;
    transform: translateZ(0);
    transition: all .9s ease;
    z-index: 4;
  }
  @include respond-to('extra-small') {
    display: block;
    .layout-container {
      padding: 6rem 1.8rem !important;
    }
    li#cause--7 {
      text-align: center;
      width: 75%;
      padding-left: 24%;
    }
  }
  @include respond-to('small') {
    .layout-container {
      padding: 6rem 1.8rem !important;
    }
    li#cause--7 {
      text-align: center;
      width: 75%;
      padding-left: 24%;
    }
  }
  @include respond-to('medium') {
    display: block;

    .layout-container {
      padding: 6rem 1.8rem !important;
    }
    li#cause--7 {
      text-align: center;
      width: 78%;
      padding-left: 29%;
    }
    .desktop-view {
      display: none;
    }
  }
  @include respond-to('medium-large') {
      display: none;
  }
}
@media (min-width: 340px) and (max-width: 398px){
  .mobile-view {
  display: block;
  li.home-causes-item-list {
    width: 50%;
    margin: 0 auto;
    padding: 0;
    float: left;
    // pseudo element to hold a square space
    &::before {
      content: '';
      display: block;
      height: 0;
      width: 100%;
    }
  }
  ul.home-causes-list {
    float: left;
    width: 100%;
    margin: 0 auto;
  }
  .home-causes-image {
      appearance: none;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      display: block;
      font-size: 22px;
      height: 100%;
      margin: 0 6%;
      top: 0;
      transition: all $timing-base $easing-base;
      width: 90%;
      padding-top: 90%;
      z-index: 10;
      border-radius: 50%;
    }
    li#cause--7 {
      text-align: center;
      width: 75%;
      padding-left: 24%;
    }
  }
  .desktop-view {
    display: none !important;
  }
  .layout-container {
    padding: 6rem 1.8rem !important;
  }
}

/* Css for min width upto 320px devices ie. iphone 5, 5s*/
/* Portrait */
@media only screen
  and (min-width: 320px)
  and (max-width: 359px)
  and (orientation: portrait) {
    ul.home-causes-list {
      float: left;
      width: 100%;
      margin: 0 auto;
    }
    .home-causes-item-list {
      padding: 0;
      width: 50%;
      float: left;
      margin: 0;
      padding: 0;
      // pseudo element to hold a square space
      &::before {
        content: '';
        display: block;
        height: 0;
        width: 100%;
      }
    }
    span.home-causes-image {
      height: 100%;
      min-height: 130px;
      width: 90%;
      margin: 0 8%;
      appearance: none;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      display: block;
      transition: all $timing-base $easing-base;
      border-radius: 50%;
  }
}
/* Landscape */
@media only screen
  and (min-width: 320px)
  and (max-width: 568px)
  and (orientation: landscape) {
    span.home-causes-image {
      height: 100%;
      min-height: 230px;
      width: 84%;
      margin: 0 8%;
      appearance: none;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      display: block;
      transition: all $timing-base $easing-base;
      border-radius: 50%;
  }
  .mobile-view {
    display: block !important;
  }
  .desktop-view {
    display: none !important;
  }
  .layout-container {
    padding: 6rem 1.8rem !important;
  }
}

@media (min-width: 400px) and (max-width: 479px){
.mobile-view {
  display: block;
  li.home-causes-item-list {
    width: 50%;
    margin: 0 auto;
    padding: 0;
    float: left;
    // pseudo element to hold a square space
    &::before {
      content: '';
      display: block;
      height: 0;
      width: 100%;
    }
  }
  ul.home-causes-list {
    float: left;
    width: 100%;
    margin: 0 auto;
  }
  .home-causes-image {
      appearance: none;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      display: block;
      font-size: 22px;
      height: 100%;
      min-height: 14.5rem;
      margin: 0 6%;
      top: 0;
      transition: all $timing-base $easing-base;
      width: 90%;
      padding-top: 90%;
      z-index: 10;
      border-radius: 50%;
    }
    li#cause--7 {
      text-align: center;
      width: 75%;
      padding-left: 24%;
    }
  }
  .desktop-view {
    display: none !important;
  }
  .layout-container {
    padding: 6rem 1.8rem !important;
  }
}
@media (min-width: 480px) and (max-width: 767px){
  .home-causes-item-list {
    padding: 0;
    width: 50%;
    float: left;
    margin: 0;
    padding: 0;
    // pseudo element to hold a square space
    &::before {
      content: '';
      display: block;
      height: 0;
      width: 100%;
    }
  }
  .mobile-view {
    display: block !important;
  }
  .desktop-view {
    display: none !important;
  }
  .layout-container {
    padding: 6rem 1.8rem !important;
  }
}

a.causes-link {
  position: relative;
  z-index: 1;
}

/*iPhone6/7/8 Plus*/
@media only screen and (min-width: 732px) and (orientation: landscape){
  .mobile-view .home-causes-image {
    width: 80%;
    padding-top: 80%;
    margin: 0 8%;
   }
}

.desktop-view .hero-text {
  padding-bottom: 8.2rem;
}
