.image-block {
  display: block;
  line-height: 0;
  margin: vr(10) auto;

  img {
    height: auto;
    width: 100%;

    + img {
      margin-top: vr(2);
    }
  }

  p {
    max-width: none;
  }

  * + .image-block-row {
    margin-top: vr(2);
  }

  .image-block-row > img {
    float: left;
    margin-top: 0;
    max-width: calc(50% - #{vr(1)});

    &:nth-child(1) {
      margin-right: vr(1);
    }

    &:nth-child(2) {
      margin-left: vr(1);
    }
  }

  // more weird nesting to make sure ElementQueries.js
  // doesn't incorrectly or overpopulate resize-sensors
  &[min-width~="700px"] {

    // img and .image-block-row elements following each other
    // should have top margin. this does not apply to other elements
    * + *:not(.caption) {
      margin-top: vr(4);
    }

    .image-block-row > img {
      margin-top: 0;
      max-width: calc(50% - #{vr(2)});

      &:nth-child(1) {
        margin-right: vr(2);
      }

      &:nth-child(2) {
        margin-left: vr(2);
      }
    }
  }
}

.image-block-row {
  @include clearfix;
  display: block;
}

// container for 2x images with separate captions
.image-block-container {
  @include clearfix;
  margin: vr(10) auto;

  .image-block {
    margin-bottom: 0;
    margin-top: 0;

    + .image-block {
      margin-top: vr(10);
    }
  }

  &[min-width~="480px"] {
    display: flex;

    .caption-text {
      border-bottom: none;
    }

    .image-block {
      border-bottom: 1px solid palette(gray, light);
      margin-bottom: 0;
      margin-top: 0;
      max-width: calc(50% - #{vr(1.5)});

      &:first-child {
        margin-right: vr(3);
      }
    }
  }

  &[min-width~="700px"] {
    .image-block {
      max-width: calc(50% - #{vr(2.5)});

      &:first-child {
        margin-right: vr(5);
      }
    }
  }
}

// image caption
.caption-text {
  @include clearfix;
  @include font-14;
  border-bottom: 1px solid palette(gray, light);
  display: block;
  padding-bottom: vr(2);
  padding-top: vr(2);

  &.rotary-identifier {
    background-image: url(/sites/all/themes/rotary_rotaryorg/images/rotary-logo.svg);
    background-position: 0 vr(2.5);
    background-repeat: no-repeat;
    background-size: 18px 18px;
    padding-left: vr(4);
  }

  .-info-graphic & {
    @include font-16;
    border-bottom: none;
    text-align: center;
  }

  p {
    margin: 0;

    + p {
      margin-top: vr(1);
    }
  }

  .caption-attribution {
    @include font-12;
  }

  &[min-width~="700px"] {
    @include font-16;
    padding-bottom: vr(4);
    padding-top: vr(4);

    p + p {
      margin-top: vr(2);
    }

    .caption-attribution {
      @include font-14;
    }
  }
}
