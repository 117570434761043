/*
  --------------------
  Parts Kit Override
  --------------------
*/
/**
 * Parts-kit has the following style rule:
 *   - layout-container.ul, layout-container.ol {
 *       max-width: none;
 *     }
 *
 * This style doesn't play well with lists and we need the following override.
 */
ul.thumbnail-list,
ul.people-list,
ol.number-list {
  max-width: 80rem;
}

/**
 * Parts-kit has the following style rule:
 *     .callout-text * {
 *       width: 100%;
 *     }
 *
 * This style doesn't play well with Statistics segment placed inside a
 * callout. We need following override to counter it.
 *
 * @todo: The style rule mentioned above might also result in unwanted
 * behaviours for other segments. So, we might need to add overrides for
 * those as well.
 */
.callout-text .text-stat * {
  width: auto;
}
.callout-text .text-stat .text-stat-list > li {
  width: 100%;
}


@include respond-to('medium') {
  /**
   * Columns & Callouts.
   */
  .columns-n-callouts {
    /**
     * When an image is applied as background to a column item, it appears
     * misplaced. i.e. the image slides to a little left outside of the
     * parent container.
     *
     * Following rule fixes this mis-placement.
     */
    > *:only-child {
      margin: 0 2.4rem;
    }

    /**
     * Respect grid widths for callout columns.
     */
    @for $i from 1 through $grid-cols {
      $percent: ((100/$grid-cols) * 1%) * $i;

      .callout-text.u-grid-#{$i} {
        @include respond-to('medium') {
          width: $percent !important;
          margin: 3.6rem auto;
        }
      }
      .callout-image.u-grid-#{$i},
      .callout-text.u-grid-#{$i}:only-child {
        @include respond-to('medium') {
          width: $percent !important;
          margin: 0 auto;
        }
      }
    }
  }
}
