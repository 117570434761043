.hero {
  @include clearfix;
  @include smooth-text;
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  min-height: 17rem;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-shadow: 0 0 4px rgba(#000, .45);
  z-index: $zindex--hero;

  // kind of hacky way to isolate IE 10 & 11 to fix a flexbox bug
  // when using min-height
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 10rem;
  }

  @include respond-to('medium') {
    min-height: 30rem;
  }

  .no-flexbox &,
  &.-title {
    height: auto;
    min-height: 0;
  }

  &.-slim {
    padding-bottom: vr(6);
    padding-top: vr(6);
  }

  &.-overflow-visible {
    overflow: visible;
  }

  &.-background-blue {
    background-color: palette(blue, x-dark);
    background-image: url(/sites/all/themes/rotary_rotaryorg/images/texture-blue.png);
    background-size: 100px 100px;
    height: auto;
    text-shadow: none;
    z-index: ($zindex--header - 1);
  }

  // same default padding as layout containers
  &.-layout-padding {
    padding: vr(7) $layout-side-padding;

    @include respond-to('medium') {
      padding: vr(10) $layout-side-padding;
    }

    @include respond-to('large') {
      padding: vr(15) $layout-side-padding;
    }
  }

  &.-tall {
    align-items: flex-end;

    // pre-set hero height to minimize height flash on load
    height: calc(100vh - #{$masthead-height--small});

    @include respond-to('medium-large') {
      height: calc(100vh - #{$masthead-height--large});
    }

    .no-flexbox & {
      display: table;
      height: 100%;
      width: 100%;
    }

    &::after {
      background: rgba(#000, .4);
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 3;

      @include respond-to('medium') {
        height: 60%;
        background: linear-gradient(
          to top,
          rgba(#000, .7) 0%,
          rgba(#000, 0) 100%
        );
      }
    }
  }
}

body.front {
  .hero {
    height: auto;
  }
}

.hero-text {
  @include no-first-last-margins;
  padding: vr(3) $layout-side-padding;
  position: relative;
  transform: translate3d(0, 0, 0);
  transition: all ($timing-base * 3) $easing-base;
  z-index: 4;

  @include respond-to('medium') {
    padding-top: vr(5);
    padding-bottom: vr(5);
  }

  @include respond-to('large') {
    padding-top: vr(7);
    padding-bottom: vr(7);
  }

  .no-flexbox .-tall & {
    display: table-cell;
    vertical-align: bottom;
  }

  @for $i from 1 through 10 {
    > :nth-child(#{$i}) {
      transition:
        opacity ($timing-base * 2) $easing-base ($timing-base * (2 + $i)),
        transform ($timing-base * 2) $easing-base ($timing-base * (2 + $i));
    }
  }

  > * {
    .js & {
      opacity: 0;
      transform: translateY(6%);
    }

    [data-is="loaded"] & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // transitions visibility on links and buttons
  // so they can't be interacted with when hidden
  // as well as overall visibility on text
  a,
  button {
    transition: visibility 0s linear 0s;
  }

  &.-scrolled {
    opacity: 0;

    a,
    button {
      transition: visibility 0s linear ($timing-base * 3);
      visibility: hidden;
    }
  }
}


/*
  --------------------
  Video background
  --------------------
*/
.messages {
  display: none;
}

.hero-video {
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  transform: translateX(-50%) translateY(0%);
  top: 0px;
  z-index: 2;
}

.hero-pause {
  appearance: none;
  background-color: rgba(#000, .4);
  bottom: vr(3);
  border: none;
  color: #fff;
  display: none;
  font-size: 1.2rem;
  padding: 0 vr(2);
  line-height: vr(6);
  position: absolute;
  right: vr(3);
  text-transform: uppercase;
  transition:
    opacity $timing-base $easing-base,
    visibility 0s linear 0s;
  z-index: 4;

  [data-video] &,
  [data-slideshow] & {
    display: inline-block;
  }

  [data-scroll-pause] & {
    opacity: 0;
    transition:
      opacity $timing-base $easing-base,
      visibility 0s linear $timing-base;
    visibility: hidden;
  }
}


/*
  --------------------
  Image background
  --------------------
*/

.hero-background,
.hero-background-placeholder,
[data-hero-slide] {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 192rem;
  margin: 0 auto;
  right: 0;

  .-tall & {
    position: absolute;

    @include respond-to('large') {
      position: fixed;
    }
  }
}

.hero-background-placeholder {
  background-color: palette(gray, dark);
  z-index: 0;
}

.hero-background {
  z-index: 1;
}

@keyframes bouncing-arrow {
  0%, 20%, 50%, 80%, 100% {
    transform: translate3d(0, 0, 0)
  }
  40% {
    transform: translate3d(0, -10px, 0)
  }
  60% {
    transform: translate3d(0, -5px, 0)
  }
}

.hero-scroll-prompt {
  animation: bouncing-arrow 2s infinite;
  animation-timing-function: ease-in-out;
  display: inline-block;
  height: 4rem;
  margin-bottom: -1.5rem;
  position: relative;
  width: 4rem;

  &::before {
    border-color: #fff;
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: block;
    height: 2rem;
    left: 50%;
    margin: -1.4rem 0 0 -1rem;
    position: absolute;
    top: 50%;
    transform: rotate(135deg);
    width: 2rem;
  }

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    animation-play-state: paused;
    background-color: transparent;
  }
}


/*
  --------------------
  Slideshow background
  --------------------
*/

@keyframes scale-slide-1 {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1.125);
  }
}

@keyframes scale-slide-2 {
  0% {
    transform: scale(1.125);
  }
  100% {
    transform: scale(1.2);
  }
}

[data-hero-slide="ready"] {
  opacity: 0;
  transform: scale(1);
  transform-origin: 50%;
  z-index: 1;

  &[data-is="current"] {
    animation: scale-slide-1 5s;
    animation-timing-function: linear;
    opacity: 1;
    transform: scale(1.125);
    z-index: 3;
  }

  &[data-is="previous"] {
    animation: scale-slide-2 3s;
    animation-timing-function: linear;
    opacity: 1;
    transform: scale(1.2);
    z-index: 2;
  }
}


/*
  --------------------
  Article mosaic
  --------------------
*/

@keyframes mosaicTransition {
  from {
    opacity: 0;
    transform: translateY(vr(4));
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-mosaic {
  @include clearfix;

  @include respond-to('medium') {
    height: 50vh;
    min-height: 45rem;
  }
}

.hero-mosaic-item {
  @include font-16;
  overflow: hidden;
  position: relative;

  @include respond-to('medium') {
    float: left;
    height: 50%;
    width: 50%;
  }

  &.-feature {
    @include respond-to('medium') {
      height: 100%;
    }

    a {
      padding-top: vr(20);
      text-shadow: 0 0 4px rgba(#000, .45);

      @include respond-to('small') {
        padding-top: vr(40);
      }

      @include respond-to('medium') {
        padding-top: vr(7);
      }
    }
  }

  .hero-background,
  .hero-background-placeholder {
    transform: scale(1.01) translate3d(0, 0, 0);
    transition: transform ($timing-base * 5) $easing-base;
  }

  &:hover {
    .hero-background,
    .hero-background-placeholder {
      transform: scale(1.1);
    }
  }

  a {
    @include smooth-text;
    color: #fff;
    display: block;
    font-weight: $font-weight--normal;
    padding: vr(10) vr(4) vr(4);
    position: relative;
    text-decoration: none;
    z-index: 5;

    @include hover {
      background-color: transparent;
    }

    [data-whatinput="keyboard"] &:focus {
      text-decoration: underline;
    }

    @include respond-to('small') {
      padding: vr(20) vr(4) vr(4);
    }

    @include respond-to('medium') {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: vr(7);
      text-align: left;
      justify-content: flex-end;
    }

    &::before {
      background: linear-gradient(
        to top,
        rgba(#000, 1) 0%,
        rgba(#000, 0) 100%
      );
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: .7;
      position: absolute;
      top: 0;
      transition: $transition--basic;
      width: 100%;
      z-index: 1;
    }

    p {
      margin: 0;

      + p {
        margin-top: vr(4);
      }
    }

    > * {
      animation: mosaicTransition ($timing-base * 2) forwards;
      animation-timing-function: ease-out;
      opacity: 0;
      position: relative;
      width: 100%;
      z-index: 2;
    }
  }
}

.hero-mosaic-item:nth-of-type(1) a > * {
  animation-delay: ($timing-base * 4);
}

.hero-mosaic-item:nth-of-type(2) a > * {
  animation-delay: ($timing-base * 5);
}

.hero-mosaic-item:nth-of-type(3) a > * {
  animation-delay: ($timing-base * 6);
}

.hero-mosaic-title {
  @include font-36;
  font-weight: $font-weight--normal;
  margin: 0;
}

.hero-mosaic-dateline {
  @include font-13;
  margin: 0;
}

.hero-callout {
  @include font-16;
  @include no-first-last-margins;
  background-color: rgba(#fff, .5);
  padding: vr(8) 2rem;

  @include respond-to('small') {
    margin-top: vr(8) !important;
  }

  a:not(.u-button) {
    @include hover {
      @include standard-hover;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  &[min-width~="400px"] {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  &[min-width~="580px"] {
    padding-left: 15%;
    padding-right: 15%;
  }
}
