*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: palette(blue);
  font-weight: $font-weight--bold;

  // removes gray tap background in iOS
  -webkit-tap-highlight-color: rgba(#000, 0);
  text-decoration: none;
  transition:
    background $timing-base $easing-base,
    color $timing-base $easing-base;

  &:active {
    // disables gray background on click in IE
    background-color: transparent;
  }

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    background-color: palette(blue, x-light);
  }
}

.u-link-small {
  @include font-14;
  font-weight: $font-weight--normal;
}

a,
button,
input,
select,
textarea {
  outline: none;
}

body {
  background-color: #fff;
  color: palette(gray, dark);
  text-size-adjust: none;
  margin: 0;
  overflow-x: hidden;
  overflow-wrap: break-word;
  padding: 0;
  width: 100%;
  word-wrap: break-word;
  word-break: break-word;

  [data-scroll="false"] &,
  &[data-mobile="navigation"] {
    height: 100%;
    overflow: hidden;
    position: fixed;
  }

  // allows click events on the body to work in iOS
  [data-whatinput="touch"] & {
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
}

button {
  // removes gray tap background in iOS
  -webkit-tap-highlight-color: rgba(#000, 0);
}

button,
input[type="submit"],
label,
select {
  cursor: pointer;
}

div,
form,
main {
  &[tabindex="-1"] {
    // remove focus outline on containers
    // that have a negative tabindex
    outline: none;
  }
}

ol,
p,
ul {
  @include template-element-maxwidth;
  margin: vr(4) auto;
}

dl {
  @include template-element-maxwidth;
  margin: vr(10) auto;
}

dt {
  @include font-20;
  font-weight: $font-weight--bold;
  margin: 0 0 vr(1);
}

dd {
  @include no-first-last-margins;
  margin: 0;

  + dt {
    margin-top: vr(6);
  }
}

em {
  font-style: italic;
}

figure {
  margin: 0;
  padding: 0;
}

hr {
  @include template-element-maxwidth;
  border: 0;
  border-top: 1px solid palette(gray, light);
  height: 0;
  margin: vr(8) auto;

  .hr-rotary-wheel & {
    margin: 0;
    max-width: none;
  }

  .hero &,
  .image-callout & {
    border-color: rgba(#fff, .3);
    margin: vr(6) auto;
  }
}

.hr-rotary-wheel {
  @include template-element-maxwidth;
  display: block;
  height: 1px;
  margin: vr(8) auto;
  text-align: center;

  &::after {
    background: #fff url(../images/rotary-logo.svg) no-repeat 50% 50%;
    background-size: 22px 22px;
    content: '';
    display: inline-block;
    height: 22px;
    position: relative;
    top: -12px;
    width: 50px;
  }

  .hero &,
  .image-callout & {
    margin: vr(6) auto;
  }
}

img {
  border: none;
  -ms-interpolation-mode: bicubic;
  image-rendering: optimizeQuality;
}

ol,
ul {
  padding: 0;

  li {
    padding-left: vr(4);
  }
}

ol {
  li {
    counter-increment: list;
    list-style-type: none;

    &::before {
      content: counter(list) '.';
      float: left;
      font-weight: $font-weight--bold;
      margin-left: vr(-4);
      width: vr(4);
    }
  }
}

ul {
  list-style: none;

  li {
    &::before {
      content: '\2022';
      display: block;
      float: left;
      margin-left: vr(-3);
    }
  }
}

li + li {
  margin-top: vr(2);
}

pre {
  font-family: monospace;
}

small {
  @include font-14;
}

strong {
  font-weight: $font-weight--bold;
}

// tables
.table-wrapper {
  @include template-element-maxwidth;
  margin: vr(10) auto;
  overflow-x: auto;
  overflow-y: hidden;

  &[min-width~="700px"] {
    table {
      white-space: normal;
    }

    th,
    td {
      padding-bottom: vr(3);
      padding-top: vr(3);
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    white-space: nowrap;

    thead tr:first-child,
    > tr:first-child {
      border-top: none;
    }
  }

  thead {
    background-color: #f4f4f4;
    text-align: left;
    white-space: nowrap;
  }

  tr {
    border-top: 1px solid #bcc0c3;
  }

  th,
  td {
    @include font-16;
    padding: vr(2) 2rem;
    vertical-align: top;
  }
}

// Main section should be width-constrained to avoid awkwardly-wide images with narrow text.
.site-main {
  max-width:  192rem;
  margin:  0 auto;
}

// fix for IE
main {
  display:  block;
}
