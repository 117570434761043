.callout {
  @include smooth-text;
  color: #fff;
  margin: vr(10) auto;

  @include respond-to('medium') {
    display: flex;
    min-height: vr(52);
  }
}

.callout-text {
  @include font-16;
  @include no-first-last-margins;
  margin: 0 vr(4);
  padding: vr(6) 0;

  + .callout-text {
    border-top: 1px solid rgba(255, 255, 255, .5);
  }

  @include respond-to('medium') {
    flex-direction: column;
    justify-content: center;

    + .callout-text {
      border-top: none;
    }
  }

  * {
    width: 100%;
  }

  a:not(.u-button):not(.share-link) {
    color: #fff;
    text-decoration: underline;

    @include hover {
      background: none;
    }
  }

  [class*="heading-"] + p,
  [class*="heading-"] + ul,
  [class*="heading-"] + ol {
    margin-top: vr(3);
  }
}

@include respond-to('medium') {
  .callout [class*="callout-"] {
    display: flex;

    p + p {
      margin-top: 0;
    }
  }
}

.callout-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: percentage(2/3);

  @include respond-to('medium') {
    padding-bottom: 0;
  }
}

@include respond-to('medium') {

  /* 1x text */
  .callout [class*="callout-"]:nth-last-child(1):first-child {
    min-height: vr(52);
    padding: vr(9) vr(4);
    width: calc(100% - #{vr(8)});
  }

  /* 2x */
  .callout [class*="callout-"]:nth-last-child(2):first-child,
  .callout [class*="callout-"]:nth-last-child(2):first-child ~ [class*="callout-"] {
    min-height: vr(52);
    width: 50%;
  }

  /* text|text, text|image, image|text */
  .callout .callout-text:nth-last-child(2):first-child,
  .callout .callout-text:nth-last-child(2):first-child ~ .callout-text,
  .callout .callout-image:nth-last-child(2):first-child ~ .callout-text {
    margin: vr(6) 0;
    min-height: vr(52);
    padding: vr(3) vr(10);
  }

  .callout .callout-text:nth-last-child(2):first-child ~ .callout-text {
    border-left: 1px solid rgba(255, 255, 255, .5);
    width: calc(50% - 1px);
  }

  .callout.no-separator .callout-text:nth-last-child(2):first-child ~ .callout-text {
    border-left: none;
  }
}


/*
  --------------------
  Vertical 2x callout
  (Join page)
  --------------------
*/

.callout-vertical {
  display: flex;
  flex-direction: column;

  @include respond-to('medium') {
    flex-direction: row;
    justify-content: space-between;
  }

  .no-flexbox & {
    @include clearfix;
    display: block;
  }
}

.callout-vertical-item-content {
  @include font-16;
  @include no-first-last-margins;
  padding: vr(5) 2rem;

  hr {
    display: none;
  }
}

.callout-vertical-item-heading {
  @include font-24;
  font-weight: $font-weight--normal;

  a {
    font-weight: inherit;
  }
}

.callout-vertical-item-intro {
  @include font-16;
  margin: 0 0 vr(6);
}

.callout-vertical-item-cta {
  margin-top: vr(8);
}

.callout-vertical-item {
  img {
    height: auto;
    margin: 0 0 0 -2rem;
    width: calc(100% + 4rem);
  }

  picture {
    width: 100%;
  }

  @include respond-to('medium') {
    align-items: center;
    display: flex;
    justify-content: center;
    width: calc(50% - 2rem);
  }

  + .callout-vertical-item {
    margin-top: vr(4);

    @include respond-to('medium') {
      margin-top: 0;
    }
  }

  &[min-width~="480px"] {
    .callout-vertical-item-content {
      padding: vr(6) 6rem;
      text-align: center;

      hr {
        display: block;
        width: 5rem;
      }

      img {
        margin: 0 0 0 -6rem;
        width: calc(100% + 12rem);
      }

      .callout-vertical-item-heading {
        margin-left: 10%;
        margin-right: 10%;
      }

      .callout-vertical-item-intro {
        margin: 0 20% vr(9);
      }
    }
  }

  .no-flexbox & {
    display: block;
  }
}

// container with border
.callout-vertical-item-bordered {
  border: 10px solid palette(gray, x-light);

  @include respond-to('medium') {
    .no-flexbox & {
      float: left;
    }
  }
}

// container with photo
.callout-vertical-item-photo {
  color: #fff;

  .callout-vertical-item-content {
    padding-top: 0 !important;
  }

  @include respond-to('medium') {
    .no-flexbox & {
      float: right;
    }
  }
}
