@mixin a11y--visually-hide {
  border: 0;
  clip: rect(1px 1px 1px 1px); /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin a11y--visually-show {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  width: auto;
}
