@mixin list-unstyled {
  li {
    margin: 0;
    padding-left: 0;

    &::before {
      display: none;
    }
  }
}
