.topic-bundle {
  @include list-unstyled;
  margin: vr(10) auto;

  @include respond-to('medium') {
    display: flex;
  }
}

li.topic-bundle-item {
  @include font-16;
  @include no-first-last-margins;
  @include smooth-text;
  background:
    palette(blue)
    url(/sites/all/themes/rotary_rotaryorg/images/texture-lighter-blue.png)
    50% 50%;
  background-size: 100px 100px;
  color: #fff;
  margin: 0;
  padding: vr(6) 2rem;
  text-align: center;

  @include respond-to('small') {
    padding-left: 20%;
    padding-right: 20%;
  }

  @include respond-to('medium') {
    padding-left: 2rem;
    padding-right: 2rem;
    width: percentage(1/3);
  }

  &:nth-child(even) {
    background-color: darken(palette(blue), 2);
    background-image: url(/sites/all/themes/rotary_rotaryorg/images/texture-darker-blue.png);
  }
}

.topic-bundle-heading {
  @include font-24;
  font-weight: $font-weight--normal;
}

.topic-bundle-learnmore {
  @include font-14;
  color: #fff;
  font-weight: $font-weight--normal;

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include standard-hover;
  }
}
