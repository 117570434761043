/*
  --------------------
  Base styles
  --------------------
*/

.audio {
  cursor: pointer;
  display: inline-block;
  position: relative;

  button {
    @include hide-text;
    appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    transition: $transition--basic;
  }
}


/*
  --------------------
  Inline player
  --------------------
*/

.audio {
  &[data-features="inline"] {
    font-weight: 700;
    padding: 0 vr(1) 0 vr(5);
    text-decoration: none;

    &::before {
      background: url(/sites/all/themes/rotary_rotaryorg/images/audio-blue.svg) no-repeat 50% 50%;
      background-size: 18px 16px;
      content: '';
      display: block;
      height: 100%;
      left: vr(1);
      position: absolute;
      top: 0;
      width: vr(3);
    }

    &[data-is="playing"]::before {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/audio-pause-blue.svg);
      background-size: 16px 16px;
    }

    &[data-is="paused"]::before {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/audio-play-blue.svg);
      background-size: 12px 16px;
    }
  }
}


/*
  --------------------
  Block/image caption player
  --------------------
*/

.audio {
  &[data-features="block"] {
    @include smooth-text;
    @include font-14;
    background-color: palette(blue);
    color: #fff;
    font-weight: $font-weight--bold;
    padding: vr(1) 1rem vr(1) 4.6rem;
    text-decoration: none;
    width: 100%;

    // layout tweaks when used inside a figcaption
    .caption & {
      margin-top: vr(1);
    }

    button {
      background: rgba(palette(blue, dark), .4) url(/sites/all/themes/rotary_rotaryorg/images/audio-white.svg) no-repeat 50% 50%;
      background-size: 16px 14px;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 3.6rem;
    }

    &[data-is="playing"] button {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/audio-pause-white.svg);
      background-size: 14px 14px;
    }

    &[data-is="paused"] button {
      background-image: url(/sites/all/themes/rotary_rotaryorg/images/audio-play-white.svg);
      background-size: 11px 14px;
    }

    &[min-width~="480px"] {
      @include font-16;
      padding: vr(3) 2rem vr(3) 7rem;

      button {
        background-size: 25px 21px;
        width: vr(10);
      }

      &[data-is="playing"] button {
        background-size: 21px 21px;
      }

      &[data-is="paused"] button {
        background-size: 16px 21px;
      }
    }

    &:not([data-is="playing"]):not([data-is="paused"]) {
      @include hover {
        button {
          background-color: palette(blue, dark);
        }
      }
    }
  }
}

// Prevents any margin getting applied to the audio button under
// image block for large widths.
.image-block {
  &[min-width~="700px"] {
    .audio {
      button {
        margin: 0;
      }
    }
  }
}
