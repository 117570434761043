// media queries
$breakpoints: (
  'extra-small': (320 / 16) * 1em,
  'small': (480 / 16) * 1em,
  'medium': (760 / 16) * 1em,
  'medium-large': (1000 / 16) * 1em,
  'large': (1200 / 16) * 1em
);

// header/nav
$nav-height--small: 0;
$nav-height--large: 5rem;

$header-height--small: 6rem;
$header-height--large: 8rem;
$myrotary-header-height--large: 10rem;

$header-container-max-width-desktop: 154rem;

$masthead-height--small: $nav-height--small + $header-height--small;
$masthead-height--large: $nav-height--large + $header-height--large;
$myrotary-masthead-height--large: $nav-height--large + $myrotary-header-height--large;

// grid
$grid-cols: 12;
$grid-gutter: 2rem;

// vertical rhythm
$vr-px: 6;
$vr: px-to-rem($vr-px);

// left/right padding for `.layout-container`
$layout-side-padding-px: ($vr-px * 3);
$layout-side-padding: px-to-rem($layout-side-padding-px);

// container widths
$widths: (
  'regular': 700,
  'wide': 800,
  'x-wide': 900,
  'xx-wide': 1000,
  'xxx-wide': 1100,
  'xxxx-wide': 1200
);
