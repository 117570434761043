body.page-content-search #page-title {
  float: left;
  padding-left : calc(calc(100vw - 95rem) / 3);
}

.rotary-federated-solr-client {
  $vertical-spacing-3: .75rem;
   border-top: 2px solid rgba(190, 196, 201, 0.6);

  @include respond-to('medium') {
      display: flex;
  }

  .search-form-container,
  .search-results-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .search-form-container {
    background-color: #edeeef;
    border-right-width: 1px;
    padding: 3rem;

    @include respond-to('medium') {
    flex: 1 1 0%;
    padding-left : calc(calc(100vw - 74rem) / 4);
    padding-right: 4rem;
    }

    @media (min-width: 1440px) {
      min-width: 38rem;
    }
  }

  .search-form-container form {
    margin-bottom: 1rem;
  }

  .search-form-container label {
    margin-bottom: $vertical-spacing-3;
  }

  .search-results-container {
    opacity: 1;
    position: relative;
    padding: 3rem 1.5rem 3rem 1.5rem;

    @include respond-to('medium') {
      flex: 2 2 0%;
      padding-right: calc(calc(100vw - 74rem) / 6);
      padding-left: 4rem;
    }

    @media (min-width: 1440px) {
      padding-right: calc(calc(100 vw - 74rem) / 2);
    }
  }

  &.loading .search-results-container {
    opacity: 0.5;

    &:after {
      position: absolute;
      top: 20px;
      left: 50%;
      width: 200px;
      height: 200px;
      content: 'loading...';
    }
  }

  .results-count, 
  .no-query-message {
    padding-top: $vertical-spacing-3;
    padding-bottom: $vertical-spacing-3;        
    margin: 0;
    font-size: 1.4rem;
  }

  .no-query-message {
    display: block;
  }

  &.has-search-active .no-query-message {
    display: none;
  }

  .results-count {
    display: none;
  }

  &.has-search-active .results-count {
    display: block;
  }

  input[name="query"] {
    padding-top: $vertical-spacing-3;
    padding-left: 3rem;
    padding-bottom: $vertical-spacing-3;
    margin-bottom: 2.4rem;
  }

  input[name="query"]::placeholder {
    color: palette(gray, dark);
    font-size: 1.5rem;
    font-weight: 200;
    opacity: 1; /* Firefox */
  }
  
  //IE override requires !important on rules
  input[name="query"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: palette(gray, dark) !important;
    font-size: 1.5rem !important;
    font-weight: 200 !important;
  }
  
  input[name="query"]::-ms-input-placeholder { /* Microsoft Edge */
    color: palette(gray, dark);
    font-size: 1.5rem;
    font-weight: 200;
  }

  .submit-button {
    min-width: 100%;
    margin-bottom: 1rem;
  }

  .facets {
    display: none;
    margin: 2.4rem 0;
  }

  &.has-search-active .facets {
    display: block;
  }

  .reset-button-container {
    text-align: center;
  }

  // Reset list styles
  .search-results,
  .search-results > li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .search-results {
    border-top-width: 3px;
    display: none; //Wait for .has-search-active

    & h3 {
      margin: 0;
    }

    & li:before {
      content: none;
    }
  }

  &.has-search-active .search-results {
    display: block;
  }

  ul.search-results{
    font-size: 1.5rem;
    border-top: 3px solid #bec4c9;
  }

  ul.search-results > li{
    padding-top: 1rem;
    border-bottom: 1px solid #bec4c9;
  }

  ul.search-results > li a.link{
    font-weight: 400;
  }

  ul.search-results .metadata {
    opacity: 0.65;
  }

  ul.cda-links,
  ul.cda-links > li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  ul.cda-links > li a {
    font-weight: normal;
  }

  .pagination {
    flex-wrap: wrap;
  }

  .content-type-select,
  .document-select,
  .language-select {
    min-width: 350px;
    min-height: 38px;
    border: 1px solid palette(gray, light);
    position: relative;
    padding: 0.1875rem 0.5rem;
    background: palette(white, light);
    margin-top: 10px;
    margin-bottom: 13px;

    &:after {
      border-style: solid;
      border-color: palette(blue, base);
      border-width: 0 3px 3px 0;
      padding: 3px;
      transform: rotate(45deg);
      position: absolute;
      top: 32%;
      right: 16px;
      content: "";
      pointer-events: none;      
    }

    & select {
      width: 100%;
      border: 0;
      background: none;

      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      appearance: none;
    }
  }

  .search-icon {
    display: block;
    position: absolute;
    padding-top: 10px;
    padding-left: 6px;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      transform-origin: center center;
    }

    &::before {
      border: 2px solid palette(blue);
      border-radius: 50%;
      left: 1rem;
      top: 12px;
      height: 12px;
      width: 12px;
    }

    &::after {
      background-color: palette(blue);
      border-radius: 0 2px 2px 0;
      height: 3px;
      margin-left: 12px;
      margin-top: 13px;
      transform: rotate(45deg);
      width: 8px;
    }
  }
}