.layout-container {
  @include clearfix;
  @include no-first-last-margins;
  background-color: #fff;
  padding: vr(7) $layout-side-padding;
  position: relative;
  z-index: $zindex--layout-container;

  @include respond-to('medium') {
    padding: vr(10) $layout-side-padding;
  }

  @include respond-to('large') {
    padding: vr(15) $layout-side-padding;
  }

  // prevents padding on two similarly-colored containers
  // from doubling up
  + :not(.-lightgray):not(.-gray):not(.-dark) {
    padding-top: 0;

    &:before {
      display: table;
      content: "";
    }
  }

  // padding modifiers
  &.-padding-reduced {
    padding-bottom: vr(6);
    padding-top: vr(6);

    @include respond-to('medium') {
      padding-bottom: vr(10);
      padding-top: vr(10);
    }
  }

  &.-padding-reduced-top {
    padding-top: vr(6);

    @include respond-to('medium') {
      padding-top: vr(10);
    }
  }

  &.-padding-reduced-bottom {
    padding-bottom: vr(6);

    @include respond-to('medium') {
      padding-bottom: vr(10);
    }
  }

  &.-padding-small {
    padding-bottom: vr(4);
    padding-top: vr(4);
  }

  &.-padding-small-top {
    padding-top: vr(4);
  }

  &.-padding-small-bottom {
    padding-bottom: vr(4);
  }

  &.-padding-none {
    padding-bottom: 0;
    padding-top: 0;
  }

  &.-padding-none-top {
    padding-top: 0;
  }

  &.-padding-none-bottom {
    padding-bottom: 0;
  }

  // background modifiers
  &.-lightgray {
    @include layout-container-padding('-lightgray');
    background-color: palette(gray, xx-light);
  }

  &.-green {
    @include layout-container-padding('-green');
    background-color: palette(green, light);
  }

  &.-blue {
    @include layout-container-padding('-blue');
    background-color: palette(blue);
  }

  &.-purple {
    @include layout-container-padding('-purple');
    background-color: palette(purple, light);
  }

  &.-red {
    @include layout-container-padding('-red');
    background-color: palette(red, light);
  }

  &.-royalblue {
    @include layout-container-padding('-royalblue');
    background-color: palette(royalblue, light);
  }

  &.-gray {
    @include layout-container-padding('-gray');
    background:
      palette(gray, xx-light)
      url(/sites/all/themes/rotary_rotaryorg/images/concrete_seamless.jpg)
      50% 0;
  }

  &.-dark {
    @include background-triangles-darkblue;
    @include layout-container-padding('-dark');
    @include smooth-text;
    color: #fff;
  }

  // fixes margin on first element if it's preceded by a pull container
  > .u-pull-container:first-child + * {
    margin-top: 0;
  }

  // container with an image callout footer
  &.-image-callout {
    padding-bottom: 0 !important;

    // all layout containers following should have top padding
    // regardless of color. using !important here to make sure it happens
    + .layout-container {
      padding-top: vr(7) !important;

      @include respond-to('medium') {
        padding-top: vr(10) !important;
      }

      @include respond-to('large') {
        padding-top: vr(15) !important;
      }

      &.-padding-small,
      &.-padding-small-top {
        padding-top: vr(4) !important;
      }
    }

    // pull the image callout container into the padding
    .image-callout {
      margin-left: $layout-side-padding * -1;
      margin-right: $layout-side-padding * -1;
    }
  }
}
