.related {
  @include clearfix;
  border-top: 1px solid palette(gray, light);
  margin: vr(10) auto;
  max-width: width('xx-wide');
  padding-top: vr(6);

  * {
    max-width: none;
  }

  a {
    color: palette(blue, x-dark);
    font-weight: $font-weight--normal;

    @include hover {
      @include standard-hover;
    }
  }

  ul {
    @include font-16;
  }

  [class*="heading-"] {
    margin-bottom: vr(3);
  }
}

.related-stories,
.related-stories-feature,
.related-resources {
  @include no-first-last-margins;
}


/*
  --------------------
  Stories
  --------------------
*/

.related-stories {
  @include respond-to('small') {
    @include clearfix;
  }

  @include respond-to('medium-large') {
    float: left;
    width: 68%;
  }
}

.related-stories-feature {
  @include respond-to('small') {
    @include font-16;
    float: left;
    margin: 0;
    width: 40%;
  }

  img {
    display: block;
    height: auto;
    margin: 0 0 vr(3);
    width: 100%;
  }
}

.related-stories-list {
  @include respond-to('small') {
    @include list-unstyled;
    float: left;
    margin: 0;
    padding-left: 5rem;
    width: 60%;

    li + li {
      margin-top: vr(4);
    }
  }
}


/*
  --------------------
  Resources
  --------------------
*/

.related-resources {
  margin-top: vr(6);

  @include respond-to('medium-large') {
    float: right;
    margin-top: 0;
    width: 26%;

    li {
      padding-bottom: vr(3);

      + li {
        border-top: 1px solid palette(gray, x-light);
        padding-top: vr(3);
      }
    }

    ul {
      @include list-unstyled;
      margin: 0;
    }
  }
}
