.final-call-section-container {
    .final-call-section-image {
        img {
            width: 100%;
            height: 440px;
        }
    }
}


.final-call-section-content {
  width: 100%;
  z-index: 10;
  padding-top: 6rem !important;
  position: relative;
  .final-call-section-stripe {
    margin-top: -8px;
  }
  
  h2 {
    font-weight: 300px;
    font-size: 36px;
    line-height: 49px;
    text-align: left;
  }

  p {
    font-size: 20px;
    line-height: 32px;
  }

  a.u-button {
    border-radius: 50px;
    float: left;
  }
}


@media (max-width:760px){
  .final-call-section-container{
    max-width: 110rem;
    display: block;
  }
  .final-call-section-content {
    width: 100%;
    padding-top: 20px;
          
    .final-call-btn {
      float: none;
      padding-top: 15px;
    }
  }
}
