/*
  --------------------
  Fieldset
  --------------------
*/

fieldset {
  border: none;
  margin: 0 !important;
  padding: 0;
}

legend {
  @include font-13;
  font-weight: $font-weight--bold;
  margin: 0 0 vr(.5);
  padding: 0;
  text-transform: uppercase;

  + .u-grid-row {
    margin-top: 0;
  }
}


/*
  --------------------
  Labels
  --------------------
*/

label {
  @include font-16;
  display: block;
  font-weight: $font-weight--bold;
  margin-bottom: vr(.5);

  .required {
    color: palette(red, error);
  }
}


/*
  --------------------
  Text inputs and textareas
  --------------------
*/

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="file"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea {
  @include input-style-base;

  &[aria-invalid="true"] {
    background-image: url(../images/icon-error.svg);
    background-position: calc(100% - 10px) 1rem;
    background-repeat: no-repeat;
    background-size: 22.5px 20px;
    padding-right: 4rem;
  }
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="file"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"] {
  appearance: none;
  height: vr(7);
  padding: 0 1.4rem;

  @include placeholder {
    color: palette(blue);
    font-weight: $font-weight--bold;
    opacity: 1;
    transition: $transition--basic;
  }

  &:focus {
    @include placeholder {
      color: palette(gray);
      opacity: .3;
    }
  }
}

textarea {
  overflow: auto;
  padding: vr(2) 1.4rem;
  resize: vertical;
}


/*
  --------------------
  Custom checkbox & radio controls
  --------------------
*/

.u-checkbox,
.u-radio {
  + label {
    display: inline;
    font-weight: $font-weight--normal;
    margin: 0;
  }
}

[data-whatinput="initial"],
[data-whatinput="mouse"] {
  .u-checkbox,
  .u-radio {
    @include a11y--visually-hide;

    + label {
      display: block;
      padding-left: 3rem;
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
      }

      &::before {
        background-color: #fff;
        border: 1px solid darken(palette(gray, light), 4);
        border-radius: 3px;
        height: 2rem;
        left: 0;
        margin-top: -1rem;
        transition: box-shadow $timing-base $easing-base;
        width: 2rem;
      }
    }
  }

  .u-checkbox {
    + label {
      &::after {
        background-image: url(../images/check.png);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        height: 2rem;
        left: 0;
        margin-top: -1rem;
        opacity: 0;
        transform: scale(.5);
        transition: $transition--basic;
        width: 2rem;
      }
    }

    &:checked {
      + label::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .u-radio {
    + label {
      &::before {
        border-radius: 50%;
      }

      &::after {
        background-color: palette(blue);
        border-radius: 50%;
        height: 1.4rem;
        left: .3rem;
        margin-top: -.7rem;
        opacity: 0;
        transform: scale(.5);
        transition: $transition--basic;
        width: 1.4rem;
      }
    }

    &:checked {
      + label::before {
        background-image: none;
      }

      + label::after {
        background-image: none;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}


/*
  --------------------
  List styles for checkbox and radio controls
  --------------------
*/

.u-input-list {
  display: inline-block;
  margin: vr(4) 0;

  // basic vertical list
  ol,
  ul {
    @include list-unstyled;
    margin: 0
  }

  li + li {
    margin-top: vr(1);
  }

  // inline list
  &.-inline {
    @include respond-to('medium') {
      @include clearfix;

      label {
        line-height: vr(7);
      }

      legend,
      .u-input-list-legend {
        float: left;
        line-height: vr(7);
        margin-bottom: 0;
        padding-right: 2rem;
      }

      li {
        float: left;
        margin: 0;

        + li {
          margin-left: 2rem;
        }
      }

      ol,
      ul {
        float: left;
        margin: 0;
      }
    }
  }
}


/*
  --------------------
  Select list
  --------------------
*/

.select {
  @include input-style-base;
  appearance: none;
  background-image: url(../images/arrow-down-blue.svg);
  background-position: calc(100% - 1.4rem) calc(50% + .2rem);
  background-repeat: no-repeat;
  background-size: 14px 8px;
  height: vr(7);
  padding: 0 4rem 0 1.4rem;

  &.-fitted {
    max-width: 100%;
    width: auto;
  }

  &[aria-invalid="true"] {
    background-image: url(../images/arrow-down-error.svg);
  }

  // hide arrow in IE
  &::-ms-expand {
    display: none;
  }
}


/*
  --------------------
  Helper text
  --------------------
*/

input,
select,
.select,
textarea,
fieldset {
  + small {
    display: block;
    margin-top: vr(.5);
    padding-left: 1.5rem;
  }

  &[aria-invalid="true"] + small {
    color: palette(red, error);
  }
}


/*
  --------------------
  Error states
  --------------------
*/

input,
select,
textarea {
  &[aria-invalid="true"] {
    background-color: palette(red, error-light);
    border-color: palette(red, error);
    box-shadow: 0 0 0 1px palette(red, error);
    color: palette(red, error);

    &:focus {
      border-color: palette(red, error);
    }
  }
}


/*
  --------------------
  Functionality Toggle
  --------------------
*/

.form-functionality-toggles {
  display: flex;
}

.form-functionality-toggle {
  @include font-15;
  appearance: none;
  background-color: transparent;
  border: none;
  color: lighten(palette(gray, dark), 20);
  font-weight: $font-weight--bold;
  margin: 0;
  overflow: visible;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  transition: $transition--basic;

  @include hover {
    color: palette(gray, dark);
  }

  &::after {
    background-color: palette(gray, dark);
    bottom: -4px;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transition: transform $timing-base $easing-base;
    width: 100%;
  }

  &.-selected {
    color: palette(gray, dark);
    cursor: default;

    &::after {
      transform: scaleX(1);
    }
  }

  + .form-functionality-toggle {
    margin-left: 3rem;
  }
}

/*
  --------------------
  Disabled State
  --------------------
*/
.u-disabled {
  color: #c1c4c7;
  input,
  select,
  textarea,
  label,
  legend{
    color: #c1c4c7;
    cursor: not-allowed;
  }

  select {
    background-image: url(../images/arrow-down-grey.svg);
  }

  label {
    .required{
       color: #c1c4c7;
    }
  }

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="email"],
  input[type="file"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="url"]{
    @include placeholder {
      color: #c1c4c7;
    }
  }
}

fieldset.u-disabled+small {
  color: #c1c4c7;
}

[data-whatinput=initial],
[data-whatinput=mouse] {
  .u-disabled {
    .u-radio+label:after {
      background-color: #c1c4c7;
    }
  }
}

