[data-collapsible-target] {
  overflow: hidden;
  transition:
    height $timing-base ease-in,
    visibility 0s linear 0s;
  visibility: visible;

  &[aria-hidden="true"] {
    transition:
      height $timing-base ease-out,
      visibility 0s linear $timing-base;
    visibility: hidden;
  }
}
