$palettes: (
  gray: (
    xx-light: #f8f9fa,
    x-light: #e8ebee,
    light: #c7ced9,
    base: #5e717d,
    dark: #39424a
  ),
  red: (
    xx-light: #fff5f5,
    light: #a4123b,
    base: #9b1238,
    xx-dark: #66192a,
    error-light: #fee0eb,
    error: #bb0043
  ),
  green: (
    light: #01a2a2,
    base: #018d8d
  ),
  blue: (
    x-light: #e5f5fa,
    base: #019fcb,
    dark: #007caf,
    x-dark: #0050a2,
    xx-dark: #263b4c,
    royal: #0c3c7c
  ),
  purple: (
    light: #963a86,
    base: #872175,
    dark: #692263
  ),
  orange: (
    light: #f6be5a,
    base: #ffc000,
    dark: #dd9400
  ),
  royalblue: (
    light: #4169E1,
    dark: #002366
  ),
  yellow: (
    light: #F7A81B
  ),
  white: (
    light: #FFFFFF
  ),
  poliored: (
    base: #eb2036
  )
);

$buttons: (
  red: #c10042,
  green: palette(green),
  blue: palette(blue),
  purple: palette(purple),
  poliored: palette(poliored)
);
