/*
  --------------------
  General
  --------------------
*/

@mixin font-base {
  font-size: 1.8rem;
  line-height: 2.6rem;

  @include respond-to('small') {
    line-height: vr(5);
  }
}

@mixin font-46 {
  font-size: 3.4rem;
  line-height: vr(8);

  @include respond-to('large') {
    font-size: 4.6rem;
    line-height: vr(9);
  }
}

@mixin font-46 {
  font-size: 3.4rem;
  line-height: vr(8);

  @include respond-to('large') {
    font-size: 4.6rem;
    line-height: vr(9);
  }
}

@mixin font-40 {
  font-size: 2.8rem;
  line-height: vr(6);

  @include respond-to('large') {
    font-size: 4rem;
    line-height: vr(8);
  }
}

@mixin font-36 {
  font-size: 2.4rem;
  line-height: vr(6);

  @include respond-to('large') {
    font-size: 3.6rem;
    line-height: vr(8);
  }
}

@mixin font-32 {
  font-size: 2.4rem;
  line-height: vr(6);

  @include respond-to('large') {
    font-size: 3.2rem;
    line-height: vr(7);
  }
}

@mixin font-26 {
  font-size: 2.4rem;
  line-height: vr(5);

  @include respond-to('large') {
    font-size: 2.6rem;
  }
}

@mixin font-24 {
  font-size: 2rem;
  line-height: vr(5);

  @include respond-to('large') {
    font-size: 2.4rem;
    line-height: vr(6);
  }
}

@mixin font-20 {
  font-size: 1.8rem;
  line-height: vr(5);

  @include respond-to('large') {
    font-size: 2rem;
    line-height: vr(6);
  }
}

@mixin font-16 {
  font-size: 1.4rem;
  line-height: vr(4);

  @include respond-to('medium') {
    font-size: 1.6rem;
  }
}

@mixin font-15 {
  font-size: 1.5rem;
  line-height: vr(4);
}

@mixin font-14 {
  font-size: 1.4rem;
  line-height: vr(4);
}

@mixin font-13 {
  font-size: 1.3rem;
  line-height: vr(4);
}

@mixin font-12 {
  font-size: 1.2rem;
  line-height: vr(4);
}

@mixin font-10 {
  font-size: 1rem;
  line-height: vr(4);
}


/*
  --------------------
  Headings
  --------------------
*/

@mixin heading-base {
  display: block;
  margin-bottom: vr(1.5);

  + p,
  + ol,
  + ul {
    margin-top: 0;
  }
}

// sizes: 'banner', 'main', 'feature'
@mixin heading-h1($size, $base: true) {
  @if $base {
    font-weight: $font-weight--normal;
    line-height: vr(7);
    margin-top: 0;
    text-align: center;

    @include respond-to('small') {
      font-weight: $font-weight--light;
    }
  }

  @if $size == 'feature' {
    font-size: 6.6rem;
    line-height: vr(12);

    .hero & {
      margin-bottom: 0;
    }
  } @else {
    font-size: 3.6rem;
    margin-bottom: vr(5);
  }

  @include respond-to('small') {
    font-size: 6.6rem;
    line-height: vr(12);
  }

  @include respond-to('medium-large') {
    @if $size == 'banner' {
      font-size: 10rem;
      line-height: vr(20);
    }
  }

  @if $size == 'banner' {
    max-width: width('xxxx-wide');
  }
}

@mixin heading-h2($weight: $font-weight--bold, $base: true) {
  @if $base {
    @include heading-base;
    margin-bottom: vr(2);
    margin-top: vr(7);

    @include respond-to('small') {
      margin-bottom: vr(3);
    }

    // centered version is used as a section break with
    // more bottom margin
    &.u-text-centered {
      margin-bottom: vr(7);
    }
  }

  @if $weight == 400 {
    font-size: 2.6rem;
    line-height: vr(6);
  } @else if $weight == 300 {
    font-size: 4.8rem;
    line-height: vr(11);
  } @else {
    font-size: 2.4rem;
    line-height: vr(5);
  }

  font-weight: $weight;

  @include respond-to('small') {
    @if $weight == 400 {
      font-size: 3rem;
      line-height: vr(7);
    } @else if $weight == 300 {
      font-size: 2.6rem;
      line-height: vr(6);
    } @else {
      font-size: 2.6rem;
      line-height: vr(6);
    }
  }

  @include respond-to('medium-large') {
    @if $weight == 300 {
      font-size: 4.8rem;
      line-height: vr(11);
    }
  }
}

@mixin heading-h3($uppercase: false, $base: true) {
  @if $base {
    @include heading-base;
    font-weight: $font-weight--bold;
    margin-top: vr(4);
  }

  @if $uppercase {
    font-size: 1.7rem;
    line-height: vr(4);
    text-transform: uppercase;

    @include respond-to('small') {
      line-height: vr(5);
    }
  } @else {
    font-size: 2rem;
    line-height: vr(5);
  }
}

@mixin heading-h4() {
  @include font-base;
  @include heading-base;
  font-weight: $font-weight--bold;
  margin-top: vr(4);
}

@mixin heading-h5() {
  @include heading-base;
  font-size: 1.5rem;
  font-weight: $font-weight--bold;
  line-height: vr(5);
  margin-top: vr(4);
  text-transform: uppercase;
}


/*
  --------------------
  Forms
  --------------------
*/

@mixin input-style-base {
  background-color: #fff;
  border: 1px solid darken(palette(gray, light), 4);
  border-radius: 0;
  color: palette(gray, dark);
  display: block;
  font-size: 1.6rem;
  margin: 0;
  transition: $transition--basic;
  width: 100%;

  &:focus {
    border-color: palette(blue);
  }
}
