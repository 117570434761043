.topic-banner {
  background-color: #fff;
  margin: vr(10) auto;

  + .topic-banner {
    margin-top: vr(-4);
  }

  @include respond-to('medium') {
    display: flex;
  }
}

.topic-banner-header {
  @include font-16;
  @include no-first-last-margins;
  @include smooth-text;
  color: #fff;
  padding: vr(4) 3rem;

  @include respond-to('small') {
    padding: vr(7) 3rem;
  }

  @include respond-to('medium') {
    width: percentage(1/3);
  }
}

.topic-banner-list {
  @include list-unstyled;
  margin: 0;
  max-width: none;
  padding: vr(2) 0;

  @include respond-to('small') {
    display: flex;
    flex-wrap: wrap;
    padding: vr(4) 0;
  }

  @include respond-to('medium') {
    width: percentage(2/3);
  }
}

li.topic-banner-item {
  margin: 0;
  padding: 0 2rem;

  @include respond-to('small') {
    width: percentage(1/2);

    &:nth-child(odd) {
      padding: 0 2rem 0 4rem;
    }

    &:nth-child(even) {
      padding: 0 4rem 0 2rem;
    }

    &:nth-child(1),
    &:nth-child(2) {
      padding-bottom: .1rem;
      position: relative;

      &::after {
        background-color: palette(gray, light);
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        position: absolute;
      }
    }

    &:nth-child(1)::after {
      left: 4rem;
      right: 2rem;
    }

    &:nth-child(2)::after {
      left: 2rem;
      right: 4rem;
    }
  }

  @include respond-to('medium') {
    &:nth-child(odd) {
      padding: 0 2rem 0 4rem;
    }

    &:nth-child(even) {
      padding: 0 4rem 0 2rem;
    }
  }

  + .topic-banner-item a {
    border-top: 1px solid palette(gray, light);

    @include respond-to('small') {
      border-top: none;
    }
  }

  a {
    @include font-16;
    @include no-first-last-margins;
    color: palette(gray, dark);
    display: block;
    font-weight: $font-weight--normal;
    padding: vr(2) 0;

    @include hover {
      background-color: #fff;

      .topic-banner-heading {
        background-color: palette(blue, x-light);
      }
    }
  }
}

.topic-banner-heading {
  display: inline-block;
  margin: 0;

  .topic-banner-header & {
    @include font-24;

    + p {
      margin-top: vr(2);
    }
  }

  .topic-banner-item & {
    @include font-base;
    color: palette(blue);

    + p {
      margin-top: vr(1);
    }
  }
}
