// Search page.
body.page-search {
  #search-form {
    padding-top: 0rem;
    padding-bottom: 0rem;

    .u-grid-row {

      .u-grid-9 {
        padding-left: 0rem;
      }
      input[type="submit"].u-button {
        min-width: 100%;
        padding: 0;
        height: 4.2rem;
      }
    }

    .google-appliance-keymatch-results{
      li {
        padding: 10px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
      }
      .heading-h3 {
        color: #39424a;
      }
    }
  }
}

// Search block.
// rotary_rotaryorg_form_element() adds a <p> wrapper around textfields.
// This <p> wrapper inherit margin values from base styles for <p> tags.
// Following code overrides that value.
.site-nav-utility-search form p {
  margin: 0;
}
