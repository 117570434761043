.homecontent-stripe {
    border: 0 solid #fff;
    &-top {
        border-top-width: 6px;
    }
    &-bottom {
        border-bottom-width: 6px;
    }
    &-blue {
        border-color: palette(blue);
    }
    &-green {
        border-color: palette(green, light);
    }
    &-purple {
        border-color: palette(purple, light);
    }
    &-red {
        border-color: palette(red, light);
    }
    &-darkblue {
        border-color: palette(blue, xx-dark);
    }
    &-orange {
        border-color: palette(orange, light);
    }
}