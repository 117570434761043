.content-effectiveness {
  @include font-16;
  color: palette(gray);

  strong {
    @include font-base;
  }
}

.content-effectiveness-yesno {
  width: 100%;

  @include respond-to('medium') {
    align-items: center;
    display: table;
  }

  button,
  input[type="submit"] {
    min-width: 0 !important;
    width: 100%;
  }
}

.content-effectiveness-yesno-text {
  margin-left: 0;
  margin-right: 0;
  text-align: center;

  @include respond-to('medium') {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
  }
}

.content-effectiveness-yesno-buttons {
  @include clearfix;
  @include list-unstyled;
  margin-left: auto;
  margin-right: auto;
  max-width: 40rem;

  @include respond-to('medium') {
    display: table-cell;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    padding-left: 3rem;
    vertical-align: middle;
    width: 100%;
  }

  li {
    float: left;
    margin: 0;
    width: 50%;

    + li {
      padding-left: $layout-side-padding;

      @include respond-to('medium') {
        padding-left: 2rem;
      }
    }
  }
}
