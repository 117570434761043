.page-cards {
  @include list-unstyled;
  margin: vr(10) auto;
  max-width: width('xxx-wide');
  padding: 0;

  @include respond-to('medium') {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .no-flexbox & {
      display: block;
      max-width: 60rem;
    }
  }
}

.page-card {
  @include font-16;
  padding: 0;
  text-align: center;

  &[data-module="in-view"] {
    opacity: 0;
    transform: translateY(#{vr(3)});
    transition: all ($timing-base * 2) $easing-base;
  }

  &[data-is="visible"] {
    opacity: 1;
    transform: translateY(0);
  }

  + .page-card {
    margin-top: vr(2);
  }

  // 2x card layout
  @include respond-to('medium') {
    display: flex;
    box-sizing: content-box;
    margin: 0 !important;
    padding: vr(6) 0 0;
    width: calc(50% - 1.6rem);

    &:nth-child(odd) {
      padding-right: vr(2);
    }

    &:nth-child(even) {
      padding-left: vr(2);
    }

    &:nth-child(-n+2) {
      padding-top: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    .no-flexbox & {
      display: block;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: 100%;

      + .page-card {
        padding-top: vr(4);
      }
    }
  }

  // 3x card layout
  @include respond-to('large') {
    display: flex;
    box-sizing: content-box;
    padding: vr(6) 0 0;
    width: calc(33.3333% - 1.6rem);

    &:nth-child(3n+1) {
      transition-delay: 0;
    }

    &:nth-child(3n+2) {
      transition-delay: $timing-base;
    }

    &:nth-child(3n+3) {
      transition-delay: ($timing-base * 2);
    }

    &:nth-child(3n+1),
    &:nth-child(3n+3) {
      padding-left: 0;
      padding-right: 0;
    }

    &:nth-child(-n+3) {
      padding-top: 0;
    }

    // allows cards to be spaced without using the
    // main container negative margin technique
    &:nth-child(3n+2) {
      padding-left: vr(4);
      padding-right: vr(4);
    }

    &:last-child {
      padding-right: 0;
    }
  }
}


/*
  --------------------
  Text-based card
  --------------------
*/

.page-card-text {
  background-color: #fff;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  color: palette(gray, dark);
  display: block;
  padding: 0 3rem vr(4);
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

  .-blue & {
    border-bottom-color: palette(blue);
  }

  .-green & {
    border-bottom-color: palette(green, light);
  }

  .-purple & {
    border-bottom-color: palette(purple, light);
  }

  .-red & {
    border-bottom-color: palette(red, light);
  }

  .-darkblue & {
    border-bottom-color: palette(blue, xx-dark);
  }
}

.page-card-text-heading {
  @include smooth-text;
  color: #fff;
  max-width: none;
  margin: 0 -3rem vr(6);
  padding: vr(6) 3rem;
  text-align: center;

  .-blue & {
    background-color: palette(blue);
  }

  .-green & {
    background-color: palette(green, light);
  }

  .-purple & {
    background-color: palette(purple, light);
  }

  .-red & {
    background-color: palette(red, light);
  }

  .-darkblue & {
    background-color: palette(blue, xx-dark);
  }
}


/*
  --------------------
  Image-based card
  --------------------
*/

.page-card-content {
  @include no-first-last-margins;
  background-color: #fff;
  color: palette(gray, dark);
  display: block;
  font-weight: $font-weight--normal;
  padding: vr(4);
  transform: translate3d(0, 0, 0);
  transition: $transition--basic;

  @include respond-to('medium') {
    padding: 0 0 vr(4);
    width: 100%;
  }

  @include hover {
    background-color: #fff;
    box-shadow: $card-shadow;
    text-decoration: none;
    transform: $card-lift;
  }

  p {
    max-width: none;
    padding: 0 vr(3);
  }
}

.page-card-image {
  display: none;

  @include respond-to('medium') {
    background-color: palette(gray, dark);
    display: block;
    height: 0;
    line-height: 0;
    margin-bottom: vr(4);
    overflow: hidden;
    padding-bottom: percentage(5/7);
    position: relative;
    width: 100%;
  }

  img {
    @include respond-to('medium') {
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: $transition--basic;
      width: 100%;
    }
  }
}

.page-card-heading {
  margin-top: 0;
  max-width: none;
  padding: 0 vr(3);
}
