.news-features {
  background-color: #fff;
  position: relative;
  z-index: 2;
  padding: 6rem 0rem!important;
}
.news-features-container {
  height:auto;
  h2, h3, h4, h5 {
    padding:0;
    margin: 0;
  }
  .hero-mosaic-item{
    width: 100%;
    height: auto;
    @include respond-to('medium-large') {
      a {
        padding: 3rem 2.44rem;
        &::before {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 33.85%, rgba(0, 0, 0, 0.8) 82.29%);
          opacity: 1;
        }
      }
    }
  }
  .-feature{
    transition-delay: 600ms !important;
    @include respond-to('medium-large') {
      height: 72rem;
      width: 63.5%;
      border-bottom: 0.6rem solid #ffc000;
      a::before {
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 51.29%);
        opacity: 1;
      }
      .hero-background {
        background-size: initial !important;
      }
    }
  }
  h2{
    font-weight: 100 !important;
    margin: 0px 0 0.99rem 0;
    line-height: 1.1;
  }
  @include respond-to('medium-large'){
    height: 76rem;
    .hero-mosaic{
      width: calc(100% - 200px);
      margin-left: auto;
      margin-right: auto;
    }
    .from-right {
      width: 36.5%;
    }
    .special-feature{
      margin-top: 3rem;
    }
  }
}

h2.news-features-title{
  font-weight: 300 !important;
  padding: 0.3rem 0 6rem;
  text-align: center;
  font-size: 3.6rem;

  @include respond-to('medium-large') {
    padding: 3.5rem 0 6rem;
  }
}

@include respond-to('medium') {
  .news-features-container {
    width: 100%;
    .hero-mosaic-item{
      width: 50%;
      height: 36rem;
      a{
        padding-top: 10rem;
      }
    }
    .-feature{
      height: 38.5rem;
      width: 100%;
      border-bottom: 0.4rem solid #ffc000;
      a{
        padding-top: 16rem;
      }
    }
  }

  .fade-in {
    opacity: 0;
    transition: opacity 250ms ease-in;
  }

  .fade-in.appear {
    opacity: 1;
  }

  .from-left {
    grid-column: 2 / 3;
    transform: translateX(-50%);
  }

  .from-right {
    grid-column: 3 / 4;
    transform: translateX(50%);
    width: 50%;
  }
  .from-left,
  .from-right {
    transition: opacity 250ms ease-in, transform 400ms ease-in;
    opacity: 0;
  }

  .from-left.appear,
  .from-right.appear {
    transform: translateX(0);
    opacity: 1;
  }
}

@include respond-to('extra-small') {
  .news-features-container {
    .hero-mosaic-item{
      height: auto;
      float: left;
      a{
        min-height: 27rem;
        padding-top: 16.38rem;
        height: inherit;
      }
    }
    .-feature{
      width: 100%;
      height: 35.5rem;
      margin-left: 0px;
      border-bottom: 0.4rem solid #ffc000;
      a{
        padding-top: 22rem;
      }
    }
    .special-feature{
      margin-top: 0;
    }
    .from-right {
      width: 100%;
    }
  }
}
