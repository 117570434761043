// fonts
$font-stack--base: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
$font-stack--full: 'Open Sans', $font-stack--base;
$font-stack--japanese: Noto Sans JP, Meiryo, 'メイリオ', Hiragino Kaku Gothic Pro, MS PGothic, Arial Unicode MS, sans-serif;
$font-stack--korean: Nanum Gothic, Malgun Gothic, Gulim, Dotum, Arial Unicode MS, sans-serif;

// font weights
$font-weight--light: 300;
$font-weight--normal: 400;
$font-weight--bold: 700;
