/*
  --------------------
  Main container
  --------------------
*/

.image-callout {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  z-index: $zindex--layout-container;

  &[data-init] {
    overflow: hidden;

    img {
      left: 50%;
      position: absolute;
      top: 50%;
      transform:
        translateX(-50%)
        translateY(-50%);
      z-index: 1;
    }
  }

  // image overlay container
  &[data-features*="text-"]::after {
    background: rgba(#000, .35);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity ($timing-base * 2) $easing-base;
    width: 100%;
    z-index: 2;
  }

  // fade out overlay when viewing image caption
  &[data-caption-visible="true"]::after {
    opacity: 0;
  }

  // "background" image treatment for use at bottom of
  // white layout containers
  &[data-features*="text-none"]::after {
    background: linear-gradient(
      to bottom,
      rgba(#fff, 1) 0,
      rgba(#fff, 0) 35%
    );
  }

  &[data-features*="overlay-none"]::after {
    display: none !important;
  }

  @include respond-to('large') {
    &[data-features*="text-left"]::after {
      background: linear-gradient(
        to right,
        rgba(#000, .6) 20%,
        rgba(#000, 0) 100%
      );
    }

    &[data-features*="text-right"]::after {
      background: linear-gradient(
        to right,
        rgba(#000, 0) 20%,
        rgba(#000, .6) 100%
      );
    }

    &[data-features*="text-bottom"]::after {
      background: linear-gradient(
        to bottom,
        rgba(#000, 0) 30%,
        rgba(#000, .7) 100%
      );
    }
  }

  .u-container {
    z-index: 5;
  }
}


/*
  --------------------
  Overlay text
  --------------------
*/

.image-callout-text-container {
  @include smooth-text;
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-align: left;
  top: 0;
  width: 100%;
  z-index: 5;

  @include respond-to('medium') {
    .image-callout[data-features*="text-bottom"] & {
      align-items: flex-end;
      text-align: center;
    }

    .image-callout[data-features*="text-center"] & {
      text-align: center;
    }

    .image-callout[data-features*="text-left"] & {
      text-align: left;
    }

    .image-callout[data-features*="text-right"] & {
      text-align: right;
    }
  }

  @include respond-to('large') {
    .image-callout[data-features*="text-left"] &,
    .image-callout[data-features*="text-right"] & {
      width: 50%;
    }

    .image-callout[data-features*="text-right"] & {
      left: auto;
      right: 0;
    }
  }
}

a.image-callout-text-container {
  @include hover {
    background-color: transparent;
  }
}

.image-callout-article,
.image-callout-cta,
.image-callout-quote,
.image-callout-text {
  @include no-first-last-margins;
  padding: vr(8) vr(4);
  max-width: width('x-wide');
  transition: all ($timing-base * 2) $easing-base;

  @include respond-to('small') {
    padding: vr(10) vr(6);
  }

  @include respond-to('medium') {
    padding: vr(10) vr(8);
  }

  .image-callout[data-init] & {
    opacity: 0;
    transform: translateY(10%);
  }

  // transitions
  .image-callout[data-in-view] & {
    opacity: 1;
    transform: translateY(0);
  }

  .image-callout[data-caption-visible="true"] & {
    opacity: 0;
    transform: translateY(-50%);
  }

  .no-flexbox & {
    display: table-cell;
    vertical-align: middle;
  }

  .image-callout[data-features*="text-left"] &,
  .image-callout[data-features*="text-right"] & {
    max-width: none;
  }

  .image-callout[data-features*="text-heading"] & {
    padding: vr(16) vr(8);
  }

  @include respond-to('large') {
    max-width: width('xx-wide');

    .image-callout[data-features*="text-center"] & {
      padding: vr(16) vr(8);
    }

    .image-callout[data-features*="text-heading"] & {
      padding: vr(30) vr(8);
    }

    .image-callout[data-features*="text-left"] & {
      padding: vr(8) 0 vr(8) vr(16);
    }

    .image-callout[data-features*="text-right"] & {
      padding: vr(8) vr(16) vr(8) 0;
    }
  }
}

// article
.image-callout-article {
  @include font-12;
  padding-bottom: vr(3);
  padding-top: vr(3);

  @include respond-to('small') {
    padding-bottom: vr(3);
    padding-top: vr(3);
  }

  @include respond-to('medium') {
    padding-bottom: vr(5);
    padding-top: vr(5);
  }

  p {
    margin: 0;
  }
}

.image-callout-article-heading {
  @include font-32;
  font-weight: $font-weight--light;
  margin: 0 auto;
  max-width: 55rem;
}

// cta
.image-callout-cta {
  @include font-24;

  p {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  @include respond-to('large') {
    hr {
      margin: vr(12) 0;
    }

    p {
      margin: vr(8) 4rem;
    }

    .image-callout[data-features*="text-left"] & {
      padding: vr(16) 0 vr(16) vr(16);
    }

    .image-callout[data-features*="text-right"] & {
      padding: vr(16) vr(16) vr(16) 0;
    }
  }
}

// quote
.image-callout-quote {
  @include font-36;
}

.image-callout-quote-attribution {
  @include font-24;
  margin-top: vr(6);

  @include respond-to('large') {
    margin-top: vr(8);
  }
}

.image-callout-img-container {
  margin: 0;
  padding: 0;
}


/*
  --------------------
  Caption
  --------------------
*/

.image-callout-caption {
  @include font-16;
  @include smooth-text;
  background-color: rgba(#39424a, .9);
  bottom: 0;
  color: #fff;
  left: 0;
  padding: vr(3) vr(3) vr(3) vr(9);
  position: absolute;
  transition: all $timing-base $easing-base;
  width: 100%;
  z-index: 5;

  p {
    margin: 0;
    max-width: none;
  }

  .image-callout[data-caption-visible="false"] & {
    transform: translateY(100%);
    transition: all $timing-base $easing-base;
  }
}

.image-callout-attribution {
  @include font-14;
}

// caption toggle button
.image-callout-caption-toggle {
  @include hide-text;
  appearance: none;
  background: transparent url(/sites/all/themes/rotary_rotaryorg/images/icon-info.svg) no-repeat 50% 50%;
  background-size: 20px 20px;
  border: none;
  border-radius: 50%;
  height: 20px;
  left: vr(4);
  margin: 0;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: vr(4);
  transition:
    top $timing-base $easing-base,
    opacity $timing-base $easing-base;
  width: 20px;
  z-index: 10;

  @include hover {
    opacity: 1;
  }

  .image-callout[data-caption-visible="false"] & {
    top: calc(-10px - #{vr(4)});
    transition:
      all $timing-base $easing-base,
      opacity $timing-base $easing-base;

    @include respond-to('small') {
      top: calc(-20px - #{vr(4)});
    }
  }
}
