$hero-type-breakpoint-desktop: 'medium';

.hero-type {
  & {
    @include clearfix;
    @include smooth-text;
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
    min-height: 17rem;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-shadow: 0 0 4px rgba(#000, .45);
    z-index: $zindex--hero;

    @include respond-to($hero-type-breakpoint-desktop) {
      text-align: center;
    } 
  }

  //Homepage-only
  body.front & {
    height: auto;
  }

  .home-hero-logo {
    height: 61px;
    width: 160px;
  }

  .title-color-yellow{
    color: palette(orange);
  }

  .home-hero-title {
    font-size: 4.3rem;
    font-weight: $font-weight--light;
    line-height: 4.3rem;
    margin: vr(3) 0;
    text-shadow: 0 0 25px #000;
    text-align: left;

    @include respond-to($hero-type-breakpoint-desktop) {
      text-align: center;
    } 

    > span {
      display: inline-block;
      font-size: 1.8rem;
      font-weight: $font-weight--normal;
      line-height: 4.3rem;
      vertical-align: middle;
    }

    @include respond-to('medium-large') {
      font-size: 7.2rem;
      line-height: 7.2rem;

      > span {
        font-size: 3rem;
        line-height: 7.2rem;
      }
    }
    
    @include respond-to('large') {
      margin: 1.8rem auto;
      max-width: 80rem; // match max-width on paragraphs
    }
    
    &.-yellow {
      color: palette(yellow, light);
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.55);
    }
    
    &.-white {
      color: palette(white, light);
      text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.55);
    }
  }

  .hero-scroll-prompt {
    background-color: palette(orange);
    border-radius: 50%;

    &::before {
      border-color: #000;
    }

    &:hover,
    [data-whatinput="keyboard"] &:focus {
      background-color: palette(orange);
    }
  }

  .overlay_image {
    max-height: 200px;
    width: auto;
    z-index: 10;
    position: relative;
    img {
      max-height: 200px;
    }
  }
  // kind of hacky way to isolate IE 10 & 11 to fix a flexbox bug
  // when using min-height
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 10rem;
  }

  @include respond-to('medium') {
    min-height: 30rem;
  }

  .no-flexbox &,
  &.-title {
    height: auto;
    min-height: 0;
  }

  &.-slim {
    padding-bottom: vr(6);
    padding-top: vr(6);
  }

  &.-overflow-visible {
    overflow: visible;
  }

  &.-background-blue {
    background-color: palette(blue, x-dark);
    background-image: url(/sites/all/themes/rotary_rotaryorg/images/texture-blue.png);
    background-size: 100px 100px;
    height: auto;
    text-shadow: none;
    z-index: ($zindex--header - 1);
  }

  // same default padding as layout containers
  &.-layout-padding {
    padding: vr(7) $layout-side-padding;

    @include respond-to('medium') {
      padding: vr(10) $layout-side-padding;
    }

    @include respond-to('large') {
      padding: vr(15) $layout-side-padding;
    }
  }

  &.-tall {
    align-items: flex-end;

    // pre-set hero height to minimize height flash on load
    height: calc(100vh - #{$masthead-height--small});

    @include respond-to('medium-large') {
      height: calc(100vh - #{$masthead-height--large});
    }

    .no-flexbox & {
      display: table;
      height: 100%;
      width: 100%;
    }

    &::after {
      background: rgba(#000, .4);
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 3;

      @include respond-to('medium') {
        height: 60%;
        background: linear-gradient(
          to top,
          rgba(#000, .7) 0%,
          rgba(#000, 0) 100%
        );
      }
    }
  }

  .hero-type-bottom-space{
    width: 100%;
    height: 20px;
  }

  .hero-type-text {
    @include no-first-last-margins;
    padding: vr(3) $layout-side-padding;
    position: relative;
    transform: translate3d(0, 0, 0);
    transition: all ($timing-base * 3) $easing-base;
    z-index: 4;

    @include respond-to('medium') {
      padding-top: vr(5);
      padding-bottom: vr(5);
    }

    @include respond-to('large') {
      padding-top: vr(7);
      padding-bottom: vr(7);
    }

    .no-flexbox .-tall & {
      display: table-cell;
      vertical-align: bottom;
    }

    @for $i from 1 through 10 {
      > :nth-child(#{$i}) {
        transition:
          opacity ($timing-base * 2) $easing-base ($timing-base * (2 + $i)),
          transform ($timing-base * 2) $easing-base ($timing-base * (2 + $i));
      }
    }

    // transitions visibility on links and buttons
    // so they can't be interacted with when hidden
    // as well as overall visibility on text
    a,
    button {
      transition: visibility 0s linear 0s;
      border-radius: 30px;
      line-height: 1rem;
    }

    .u-link-small{
      padding: 20px;
      background:none;
      border-radius: 30px;
      border: #fff 1px solid;
      text-transform: uppercase;
      color: #fff;
      display: inline-block;
      min-width: 25rem;
    }

    .u-link-small:hover{
      padding: 20px;
      background:none;
    }

    &.-scrolled {
      opacity: 0;

      a,
      button {
        transition: visibility 0s linear ($timing-base * 3);
        visibility: hidden;
      }
    }
  }

  // load-in transition for .hero-type-text
  html.js & .hero-type-text > * {
    opacity: 0;
    transform: translateY(6%);

  }

  html.js &[data-is="loaded"] .hero-type-text > * {
    opacity: 1;
    transform: translateY(0);
  }

  /*
    --------------------
    Video background
    --------------------
  */

  .hero-type-video {
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    transform: translateX(-50%) translateY(0%);
    top: 0px;
    z-index: 2;
  }

  .hero-type-pause {
    appearance: none;
    background-color: rgba(#000, .4);
    bottom: vr(3);
    border: none;
    color: #fff;
    display: none;
    font-size: 1.2rem;
    padding: 0 vr(2);
    line-height: vr(6);
    position: absolute;
    right: vr(3);
    text-transform: uppercase;
    transition:
      opacity $timing-base $easing-base,
      visibility 0s linear 0s;
    z-index: 4;

    [data-video] &,
    [data-slideshow] & {
      display: inline-block;
    }

    [data-scroll-pause] & {
      opacity: 0;
      transition:
        opacity $timing-base $easing-base,
        visibility 0s linear $timing-base;
      visibility: hidden;
    }
  }

  /*
    --------------------
    Image background
    --------------------
  */
 
  $hero-type-background-position-x-default: 30%;

  .hero-type-background,
  .hero-type-background-placeholder,
  [data-hero-type-slide] {
    background-position-x: $hero-type-background-position-x-default;
    background-position-y: 30%;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    max-width: 192rem;
    margin: 0 auto;
    right: 0;
  }

  // Adjust background position based on 'mobile-display' parameter
  &.hero-type-mobile-display-center,
  &.hero-type-mobile-display-full-width {
    .hero-type-background,
    .hero-type-background-placeholder,
    [data-hero-type-slide] {
      background-position-x: 50%;

      @include respond-to($hero-type-breakpoint-desktop) {
        background-position-x: $hero-type-background-position-x-default;
      }
    }
  }

  &.hero-type-mobile-display-left {
    .hero-type-background,
    .hero-type-background-placeholder,
    [data-hero-type-slide] {
      background-position-x: 0%;

      @include respond-to($hero-type-breakpoint-desktop) {
        background-position-x: $hero-type-background-position-x-default;
      }
    }
  }

  &.hero-type-mobile-display-right {
    .hero-type-background,
    .hero-type-background-placeholder,
    [data-hero-type-slide] {
      background-position-x: 100%;

      @include respond-to($hero-type-breakpoint-desktop) {
        background-position-x: $hero-type-background-position-x-default;
      }
    }
  }
  // End adjustments

  .hero-type-background {
    z-index: 1;
  }

  @keyframes bouncing-arrow {
    0%, 20%, 50%, 80%, 100% {
      transform: translate3d(0, 0, 0)
    }
    40% {
      transform: translate3d(0, -10px, 0)
    }
    60% {
      transform: translate3d(0, -5px, 0)
    }
  }

  .hero-type-scroll-prompt {
    animation: bouncing-arrow 2s infinite;
    animation-timing-function: ease-in-out;
    display: inline-block;
    height: 4rem;
    margin-bottom: -1.5rem;
    position: relative;
    width: 4rem;

    &::before {
      border-color: #fff;
      border-style: solid;
      border-width: 2px 2px 0 0;
      content: '';
      display: block;
      height: 2rem;
      left: 50%;
      margin: -1.4rem 0 0 -1rem;
      position: absolute;
      top: 50%;
      transform: rotate(135deg);
      width: 2rem;
    }

    &:hover,
    [data-whatinput="keyboard"] &:focus {
      animation-play-state: paused;
      background-color: transparent;
    }
  }


  /*
    --------------------
    Slideshow background
    --------------------
  */

  @keyframes scale-slide-1 {
    0% {
      opacity: 0;
      transform: scale(1);
    }
    60% {
      opacity: 1;
    }
    100% {
      transform: scale(1.125);
    }
  }

  @keyframes scale-slide-2 {
    0% {
      transform: scale(1.125);
    }
    100% {
      transform: scale(1.2);
    }
  }

  [data-hero-type-slide="ready"] {
    opacity: 0;
    transform: scale(1);
    transform-origin: 50%;
    z-index: 1;

    &[data-is="current"] {
      animation: scale-slide-1 5s;
      animation-timing-function: linear;
      opacity: 1;
      transform: scale(1.125);
      z-index: 3;
    }

    &[data-is="previous"] {
      animation: scale-slide-2 3s;
      animation-timing-function: linear;
      opacity: 1;
      transform: scale(1.2);
      z-index: 2;
    }
  }

  // Special case - mobile display full-width. Moves the image out of the background on mobile-only.
  &.hero-type-mobile-display-full-width {
    // Doing a mobile-only media query to do these specific overrides without having to rewrite the entire component.
    @media screen and ( max-width: (map-get($breakpoints, $hero-type-breakpoint-desktop) - (1em/16) ) ) {
      & {
        height: auto !important; //!important is necessary to override JS-inserted style="height:..."
        display: block; //override flex display
        text-shadow: none;
        color: inherit; //Revert to using body text color instead of white
      }

      .hero-type-image {
        position: relative;
        width: 100%;
        padding-top: 100% / (4/3); // enforce aspect ratio: https://css-tricks.com/aspect-ratio-boxes/
      }

      .home-hero-title {
        &,
        &.-white,
        &.-yellow {
          text-shadow: none;
          color: inherit; //Revert to using body text color instead of white
        }
      }

      .hero-type-background,
      .hero-type-background-placeholder,
      [data-hero-type-slide] {
        height: 100% !important; //!important is necessary to override JS-inserted style="height:..."
      }

      &.-tall:after {
        display: none; // Disable the darkening screen over the image
      }

      //Restore button styles
      .u-button-reversed-white {
        background-color:  palette(blue, base);
        border-color:  palette(blue, base);
        color: #fff;

        &:hover,
        [data-whatinput="keyboard"] &:focus {
          @include smooth-text;
          background-color: transparent;
          color: palette(blue, base);
        }
      }
    }
  }
}

