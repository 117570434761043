.impact-narrative-image {
    @include clearfix;
    @include smooth-text;
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 840px;
    overflow: hidden;
    position: relative;
    object-fit: none;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.final-call-container {
  .final-call-content {
    margin-top: 10rem;
    max-width: 50rem;
    text-align: center;

    p {
        font-style: italic;
    }

    a {
        color: #39424A;
        font-weight: 300;
        -webkit-tap-highlight-color: transparent;
        text-decoration: none;
        border-bottom: solid 2px #BEC4C9;
        transition: background .3s ease,color .3s ease;
        line-height: 5.23rem;

        .arrow {
            border: solid #019fcb;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 2px;
            margin-bottom: 2px;
          }
          
          .right {
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
          }
    }

 }
 .final-call-content a:hover {
    background: none;
 }
}


@media (max-width:768px) {
    .impact-narrative-image {
        @include clearfix;
        @include smooth-text;
        display: flex;
        width: 100%;
        justify-content: center;
        min-height: 680px;
        overflow: hidden;
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .image--bottom-center {
      background-position: 50% 50%;
    }
    
    .image--bottom-right {
      background-position: 100% 0;
    }
    
    .image--bottom-left {
      background-position: -15px 100%;
    }

    .final-call-container {
    .final-call-content {
        margin-top: 0rem;
        max-width: 70rem;
        text-align: left;

    a {
        border-bottom: none;
        line-height: 4.23rem;
    }
    }
  }
}
