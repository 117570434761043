/*
  --------------------
  Drupal Override
  --------------------
*/

#functionality-tabs ul.primary {
    border-bottom: none;
    border-collapse: inherit;
    height: auto;
    line-height: normal;
    list-style: none;
    margin: inherit;
    padding: inherit;
    white-space: inherit;
    li a {
        background-color: inherit;
        border-color: inherit;
        border-style: inherit;
        border-width: inherit;
        height: auto;
        margin-right: inherit;
        padding: 0;
        text-decoration: none;
        &:hover{
            background-color: inherit;
            border-color: inherit;
            border-bottom-color: inherit;
        }
    }
    li.active a{
        background-color: inherit;
        border: inherit;
        border-bottom: inherit;
    }
    li:before {
    content: none;
    }
    li:first-child{
    padding-left: 0;
    }
}

#hero-2,#hero-3 {
    .hero-mosaic-description {
        display: none;
    }
}

.article-filters {
    .article-filters-search {
        display: none;
        input.form-autocomplete {
            background-image: none;
        }
    }
    #edit-submit-featured-content{
      display: none;
    }
}

ul.pager {
    li::before {
        content: '';
    }
}

// Prevents the blue shading behind the globe area on front page from cutting
// off by the following item.
section.home-causes.layout-container.-gray:before {
    display: block;
}

.node-type-legacy {
    .region-content {
        padding-top: 10rem;

        .field-name-field-byline {
          .field-label {
            display: none;
          }
          margin: 2.6rem auto;
          max-width: 80rem;
        }
        .user-picture,
        ul.contextual-links,
        div.submitted,
        .form-item,
        .field-name-field-publish-date,
        .field-name-field-featured-story-head,
        .field-name-field-featured-story-content,
        .field-name-field-feature-news-type,
        .field-name-field-source-name,
        .field-name-field-bus-review-req,
        .field-name-field-placement-location,
        .field-name-field-feature-member-news,
        .field-name-field-featured-story-module,
        .field-name-field-top-story,
        .field-name-field-aggregate-display,
        .field-name-field-add-new-translation,
        .field-name-field-short-description,
        .field-name-field-topic,
        ul.links.inline {
            display: none;
        }
        h2, h3, h4 {
            text-align: center !important;
        }
    }
}
.join-button {
    display: block;
    margin: 0 auto,
}
