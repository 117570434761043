// animation timing
$timeline-timing-circle: $timing-base;
$timeline-timing-text: $timing-base * 2;
$timeline-timing-line: $timing-base * 2;

// animation delays
$timeline-delay-circle: 0;
$timeline-delay-text: 0;
$timeline-delay-line: $timeline-delay-text + $timeline-timing-text;
$timeline-delay: $timeline-delay-line + $timeline-timing-line - $timing-base;

.timeline {
  margin: vr(10) auto;

  ol {
    font-size: 1.4rem;
    line-height: vr(4);
    margin: 0 auto;

    .u-pull-container & {
      margin: 0;
    }
  }

  .timeline-heading {
    @include font-16;
    margin: 0;

    + p {
      margin-top: vr(1);
    }
  }

  li {
    @include no-first-last-margins;
    margin: 0;
    overflow: hidden;
    padding: 0 0 vr(4) vr(5);
    position: relative;

    &:last-child {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }

    // circle
    &::before {
      background-color: #fff;
      border-style: solid;
      border-width: 2px;
      border-radius: 50%;
      content: '';
      display: block;
      height: vr(3);
      left: 0;
      margin: 0;
      opacity: 0;
      position: absolute;
      top: vr(.5);
      transition:
        opacity $timeline-timing-circle $easing-base,
        transform $timeline-timing-circle $easing-base;
      transform: scale(.5);
      transform-origin: center center;
      width: vr(3);
      z-index: 2;
    }

    // dotted line
    &::after {
      content: '';
      display: block;
      height: 0;
      left: vr(1.25);
      position: absolute;
      top: vr(4.25);
      transition: height $timeline-timing-line $easing-base;
      width: 2px;
      z-index: 1;
    }

    .js & {
      > * {
        opacity: 0;
        transform: translateY(15%);
        transition:
          opacity $timeline-timing-text $easing-base,
          transform $timeline-timing-text $easing-base;
      }
    }
  }

  &[min-width~="480px"] {
    ol {
      font-size: 1.8rem;
      line-height: vr(6);
    }

    .timeline-heading {
      font-size: 2.4rem;
      line-height: vr(5);
    }

    li {
      padding: 0 0 vr(6) vr(7);

      &:last-child {
        padding-bottom: 0;
      }

      // circle
      &::before {
        height: vr(4);
        width: vr(4);
      }

      // dotted line
      &::after {
        left: vr(1.85);
        top: vr(5.25);
      }
    }
  }

  // transition
  li:first-child,
  &[data-is="visible"] li {
    > * {
      opacity: 1;
      transform: translateX(0);
    }

    &::before {
      opacity: 1;
      transform: scale(1);
    }
  }

  &[data-is="visible"] li {
    &::after {
      height: 100%;
    }
  }

  // animation
  @for $i from 2 through 20 {
    &[data-is="visible"] li:nth-child(#{$i}) {
      $calculated-delay: $timeline-delay * ($i - 2);
      $loop-delay: $calculated-delay + ($timeline-timing-line - $timing-base);

      @if $i == 2 {
        $loop-delay: $timeline-timing-line - $timing-base;
      }

      // circle
      &::before {
        transition-delay: $loop-delay + $timeline-delay-circle;
      }

      // text
      > * {
        transition-delay: $loop-delay + $timeline-delay-text;
      }

      // line
      &::after {
        transition-delay: $loop-delay + $timeline-delay-line;
      }
    }
  }

  // colors
  &.-blue {
    li::before {
      border-color: palette(blue);
    }

    li::after {
      @include dotted-line(palette(blue));
    }
  }

  &.-green {
    li::before {
      border-color: palette(green);
    }

    li::after {
      @include dotted-line(palette(green));
    }
  }

  &.-purple {
    li::before {
      border-color: palette(purple);
    }

    li::after {
      @include dotted-line(palette(purple));
    }
  }

  &.-red {
    li::before {
      border-color: palette(red);
    }

    li::after {
      @include dotted-line(palette(red));
    }
  }
}

.timeline-header {
  @include font-16;
  margin: 0 0 vr(3) vr(5);
}
