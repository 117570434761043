.responsive-video {
  margin-bottom: vr(10);
  margin-top: vr(10);

  figcaption {
    @include no-first-last-margins;
    font-weight: $font-weight--bold;
    padding: vr(2) 0 0;
    text-align: center;

    p {
      margin-bottom: vr(2);
      margin-top: vr(2);
    }
  }

  &.u-width-full {
    figcaption {
      padding-left: $layout-side-padding;
      padding-right: $layout-side-padding;

      p {
        @include template-element-maxwidth;
      }
    }
  }
}

.responsive-video-wrapper {
  height: 0;
  line-height: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.responsive-video-media {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
