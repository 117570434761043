%grid-item {
  @include respond-to('medium') {
    display: block;
    float: left;
    margin: 0;
    min-height: 1px;
    padding-left: $grid-gutter;

    .-no-gutter & {
      padding-left: 0;
    }
  }
}

.u-grid-row {
  margin-bottom: vr(4);
  margin-top: vr(4);

  + .u-grid-row {
    margin-top: $grid-gutter;
  }

  &.-no-gutter {
    margin-bottom: 0;
    margin-top: 0;
  }

  [class*="u-grid-"] {
    @include no-first-last-margins;

    + [class*="u-grid-"] {
      margin-bottom: vr(4);
      margin-top: vr(4);

      @include respond-to('medium') {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  @include respond-to('medium') {
    @include clearfix;
    margin-left: $grid-gutter * -1;

    &.-no-gutter {
      margin-left: 0;
    }
  }
}

@for $i from 1 through $grid-cols {
  $percent: ((100/$grid-cols) * 1%) * $i;

  .u-grid-#{$i} {
    @extend %grid-item;

    @include respond-to('medium') {
      width: $percent;
    }
  }

  .u-grid-offset-#{$i} {
    @include respond-to('medium') {
      margin-left: $percent;
    }
  }
}
