// fade in
[data-animate="fade-in"] {
  transition: opacity ($timing-base * 2) $easing-base;

  &[data-is="ready"] {
    opacity: 0;
  }

  &[data-is="visible"] {
    opacity: 1;
  }
}

// fade up
[data-animate="fade-up"] {
  transition: all ($timing-base * 2) $easing-base;

  &[data-is="ready"] {
    opacity: 0;
    transform: translateY(#{vr(9)});
  }

  &[data-is="visible"] {
    opacity: 1;
    transform: translateY(0);
  }
}

// fade down
[data-animate="fade-down"] {
  transition: all ($timing-base * 2) $easing-base;

  &[data-is="ready"] {
    opacity: 0;
    transform: translateY(#{vr(-9)});
  }

  &[data-is="visible"] {
    opacity: 1;
    transform: translateY(0);
  }
}

// blur in
[data-animate="blur-in"] {
  transition:
    filter ($timing-base * 2) $easing-base,
    opacity ($timing-base * 2) $easing-base;

  &[data-is="ready"] {
    filter: blur(10px);
    opacity: 0;
  }

  &[data-is="visible"] {
    filter: blur(0);
    opacity: 1;
  }
}
