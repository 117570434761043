/*
  --------------------
  Base button styles
  --------------------
*/

.u-button,
.site-nav .u-button {
  appearance: none;
  background-color: palette(blue, base);
  border: 1px solid palette(blue, base);
  border-radius: 28px;
  color: #fff;
  display: inline-block;
  font-size: 1.5rem;
  font-weight: $font-weight--bold;
  line-height: vr(2.7);
  margin: 0;
  min-width: 25rem;
  padding: vr(3.5) 3rem;
  text-align: center;
  text-shadow: none;
  text-transform: uppercase;
  transition:
    background $timing-base $easing-base,
    border $timing-base $easing-base,
    color $timing-base $easing-base;

  // shorter button
  &.-slim {
    padding: vr(2) 3rem;
  }

  // width fitted to text
  &.-width-auto {
    min-width: 0;
    width: auto;
  }

  // fully fluid width buttons
  &.-fluid {
    min-width: 0;
    width: 100%;
  }

  // fluid width buttons that max out at a specific width
  &.-fluid-max-20rem {
    max-width: 100%;
    min-width: 0;
    width: 20rem;
  }

  &.-fluid-max-25rem {
    max-width: 100%;
    min-width: 0;
    width: 25rem;
  }

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include un-smooth-text;
    background-color: transparent;
    color: palette(blue, base);
  }

  // only fluid on small screens
  &.-fluid-small {
    min-width: 0;
    width: 100%;

    @include respond-to('small') {
      min-width: 25rem;
      width: auto;
    }
  }
}


/*
  --------------------
  Collapsible toggle variation
  --------------------
*/

.u-collapsible-toggle {
  border-color: palette(blue);
  border-radius: 6px;
  border-width: 2px;
  color: palette(blue);
  max-width: 100%;
  width: 33rem;

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include smooth-text;
    background-color: palette(blue);
    color: #fff;

    &::after {
      background-image: url(../images/arrow-down-white.svg);
    }
  }

  &[aria-expanded="true"]::after {
    transform: rotate(180deg);
    transition: transform $timing-base ease-out;
  }

  &::after {
    background: url(../images/arrow-down-blue.svg) no-repeat 50% 50%;
    background-size: 14px 8px;
    content: '';
    display: inline-block;
    height: 8px;
    margin: -.1rem 0 0 .7rem;
    transition: transform $timing-base ease-in;
    transform-origin: center center;
    width: 14px;
  }
}


/*
  --------------------
  Button colors
  --------------------
*/

.u-button-hero {
  background-color: #fff;
  color: palette(red);

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include smooth-text;
    background-color: palette(red);
    border-color: palette(red);
    color: #fff;
  }
}

.u-button-reversed-hero {
  @include smooth-text;
  background-color: palette(red);
  border-color: palette(red);
  color: #fff;

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include un-smooth-text;
    background-color: #fff;
    border-color: #fff;
    color: palette(red);
  }
}

@each $color, $tone in $buttons {

  // solid colored button
  .u-button-#{$color},
  .site-nav .u-button-#{$color} {
    @include smooth-text;
    background-color: map-get($buttons, $color);
    border-color: map-get($buttons, $color);

    &:hover,
    [data-whatinput="keyboard"] &:focus {
      @include un-smooth-text;
      background-color: transparent;
      color: map-get($buttons, $color);
    }
  }

  // reverse (transparent background) colored button
  .u-button-reversed-#{$color},
  .site-nav .u-button-reversed-#{$color} {
    background-color: transparent;
    border-color: map-get($buttons, $color);
    color: map-get($buttons, $color);

    &:hover,
    [data-whatinput="keyboard"] &:focus {
      @include smooth-text;
      background-color: map-get($buttons, $color);
      color: #fff;
    }
  }

  // button colors reacts to background color of parent container
  .u-background-#{$color} .u-button {
    @include smooth-text;

    &:hover,
    [data-whatinput="keyboard"] &:focus {
      @include un-smooth-text;
      background-color: #fff;
      color: palette($color);
    }
  }

  .u-background-#{$color} .u-button-reversed {
    background-color: #fff;
    color: palette($color);
    border: 1px solid #fff;

    &:hover,
    [data-whatinput="keyboard"] &:focus {
      @include smooth-text;
      background-color: transparent;
      color: #fff;
    }
  }
}

.u-button-white {
  background-color: #fff;
  border: none;
  color: palette(gray);

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include smooth-text;
    background-color: palette(gray);
    color: #fff;
  }
}

.u-button-reversed-white {
  background-color: transparent;
  border-color: #fff;
  color: #fff;

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include smooth-text;
    background-color: #fff;
    color: #000;
  }
}

.u-button-black {
  @include smooth-text;
  background-color: #000;
  border-color: #000;
  color: #fff;

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    background-color: transparent;
    color: #000;
  }
}

.u-button-reversed-black {
  background-color: transparent;
  border-color: #000;
  color: #000;

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include smooth-text;
    background-color: #000;
    color: #fff;
  }
}

.u-background-darkblue .u-button {
  &:hover,
  [data-whatinput="keyboard"] &:focus {
    background-color: #fff;
    color: palette(blue, xx-dark);
  }
}

.u-background-darkblue .u-button-reversed {
  background-color: #fff;
  color: palette(blue, xx-dark);

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    @include smooth-text;
    background-color: transparent;
    color: #fff;
  }
}

// disabled state
.u-button {
  &.-disabled,
  &[disabled] {
    @include smooth-text;
    background-color: #c1c4c7;
    border-color: #c1c4c7;
    color: #fff;
    cursor: not-allowed;
  }
}

[class*="u-button-reversed"] {
  &.-disabled,
  &[disabled] {
    @include un-smooth-text;
    background-color: transparent;
    border-color: #c1c4c7;
    color: #c1c4c7;
    cursor: not-allowed;
  }
}


/*
  --------------------
  Button groups
  --------------------
*/
.u-button-group {
  @include clearfix;
  animation: 0.1s element-queries;

  .u-button {
    max-width: none;
    min-width: 0;
    width: 100%;
    + .u-button {
      margin-top: 20px;
    }
  }
}

// This code is specific to u-width-8 to u-width-12 ,
// If we have two buttons this would calculate the width and divides,
// it to fit the container in a row. 
// 2x button
// Note: for this media query to work, ElementQueries.js adds a container,
// throwing off the element query count by 1: 2 becomes 2
.u-width-8, .u-width-9, .u-width-10, .u-width-11, .u-width-12 { 
  .u-button-group  {
  
  @media(min-width:400px) {
    .u-button:nth-last-child(3):first-child,
    .u-button:nth-last-child(3):first-child ~ .u-button {
      float: left;
      width: calc(50% - 10px);
    }
    .u-button:nth-last-child(3):first-child ~ .u-button {
      margin-left: 20px;
      margin-top: 0;
    }
  }
  // 3x button
  // Note: for this media query to work, ElementQueries.js adds a container,
  // throwing off the element query count by 1: 3 becomes 4
  // If we have 3 buttons inside a container then it stacks vertically.
  @media(min-width:715px) {
    .u-button:nth-last-child(4):first-child,
    .u-button:nth-last-child(4):first-child ~ .u-button {
      float: left;
      width: calc(33.3334% - 13.3334px);
    }
    .u-button:nth-last-child(4):first-child ~ .u-button {
      margin-left: 20px;
      margin-top: 0;
    }
  }
}
}

  // This code is specific to u-width-6 as it needs different behavior,
  // If we have two buttons this would calculate the width and divides,
  // it to fit the container in a row.

  // 2x button
  // Note: for this media query to work, ElementQueries.js adds a container,
  // throwing off the element query count by 1: 2 becomes 2
  // 3x button
  // Note: By default if we have 3 buttons with u-width-6,
  // it would stack all the buttons vertically.

.u-width-6, .u-width-7 { 
  .u-button-group {
  @media(min-width:400px) {
    .u-button:nth-last-child(3):first-child,
    .u-button:nth-last-child(3):first-child ~ .u-button {
      float: left;
      width: calc(50% - 10px);
    }
    .u-button:nth-last-child(3):first-child ~ .u-button {
      margin-left: 20px;
      margin-top: 0;
    }
  }
}
}