/*
  --------------------
  Base styles
  --------------------
*/

.accordion {
  [role="tabpanel"] {
    overflow: hidden;
    transition:
      height $timing-base ease-out,
      visibility 0s linear 0s;
    visibility: visible;

    &[aria-hidden="true"] {
      transition:
        height $timing-base ease-out,
        visibility 0s linear $timing-base;
      visibility: hidden;
    }
  }

  [data-accordion-content] {
    @include no-first-last-margins;
  }
}


/*
  --------------------
  Form accordion
  --------------------
*/

.form-accordion {
  @include template-element-maxwidth;
  margin: vr(10) auto;
}

.form-accordion-tab {
  @include font-20;
  @include smooth-text;
  background-color: palette(blue, xx-dark);
  border-top: 1px solid palette(gray, dark);
  font-weight: $font-weight--bold;
  margin: 0;

  &:first-of-type {
    border: none;
  }

  a {
    color: #fff;
    display: block;
    padding: vr(1) 2rem;
    position: relative;

    @include hover {
      background: transparent;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 3.6rem;

      @include respond-to('large') {
        width: 4.8rem;
      }
    }

    &::after {
      background-color: rgba(#000, .2);
      z-index: 1;
    }

    &::before {
      background: url(/sites/all/themes/rotary_rotaryorg/images/arrow-down-white.svg) no-repeat 50% 50%;
      background-size: 14px 8px;
      transition: $transition--basic;
      z-index: 2;
    }

    &[aria-selected="true"]::before {
      transform: rotate(180deg);
    }
  }
}

.form-accordion-tabpanel {
  background-color: darken(palette(gray, xx-light), 2);
}

.form-accordion-tabcontent {
  padding: vr(5) 2rem;
}
