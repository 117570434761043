/*
  --------------------
  Main containers
  --------------------
*/

.slideshow {
  margin: vr(10) auto;
  overflow: hidden;
  position: relative;
}

.slideshow-scroll-track {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 500px; // arbitrary height to hide scrollbar
  scroll-snap-points-x: repeat(100%);
  scroll-snap-type: mandatory;
  width: 100%;
}


/*
  --------------------
  Caption container
  (javascript-generated)
  --------------------
*/
span[data-slide-caption] {
  display: none;
}

.slideshow-caption-container {
  @include template-element-maxwidth;
  background-color: #fff;
  margin: vr(-10) auto vr(10) auto;
  padding-left: vr(20);
  padding-right: vr(4);
  position: relative;
  transform: translate3d(0, 0, 0); // fix for Chrome hiding top of caption under slides
}

.slideshow[min-width~="#{ map-get($widths, 'wide') + ($layout-side-padding-px * 2) }px"],
.template-story .slideshow[min-width~="#{ map-get($widths, 'regular') + ($layout-side-padding-px * 2) }px"] {
  + .slideshow-caption-container {
    margin-top: vr(-17);
  }
}

// caption box prev/next & counter container
.slideshow-caption-controls {
  float: left;
  margin-left: vr(-16);
  width: vr(12);

  p + p {
    margin-top: vr(1);
  }
}

// prev/next
.slideshow-caption-prev-next {
  @include clearfix;
}

.caption-next,
.caption-previous {
  @include hide-text;
  appearance: none;
  background-color: transparent;
  border: none;
  height: vr(6);
  margin: 0;
  padding: 0;
  position: relative;
  transition: $transition--basic;
  width: vr(6);

  &::before,
  &::after {
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transition: $transition--basic;
  }

  &::before {
    border-right: 2px solid palette(blue);
    border-top: 2px solid palette(blue);
    height: 12px;
    transform: rotate(45deg);
    width: 12px;
  }

  &::after {
    background-color: palette(blue);
    height: 2px;
    margin: -1px 0 0 -8px;
    width: 16px;
  }

  &:hover,
  [data-whatinput="keyboard"] &:focus {
    background-color: palette(blue);

    &::before {
      border-right-color: #fff;
      border-top-color: #fff;
    }

    &::after {
      background-color: #fff;
    }
  }
}

.caption-next {
  float: right;

  &::before {
    margin: -6px 0 0 -4px;
    transform: rotate(45deg);
  }
}

.caption-previous {
  float: left;

  &::before {
    margin: -6px 0 0 -8px;
    transform: rotate(-135deg);
  }
}

// counter
.slideshow-counter {
  @include font-14;
  text-align: center;
}

// hide individual captions
.slideshow-slide-caption {
  [data-module="slideshow"][data-init] & {
    display: none;
  }
}


/*
  --------------------
  Individual slide
  --------------------
*/

.slideshow-list {
  @include list-unstyled;
  margin: 0;
  max-width: none !important;
  padding: 0;
}

.slideshow-slide {
  margin: 0;
  padding: 0;

  .slideshow[data-init] & {
    float: left;
  }

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}


/*
  --------------------
  Next/Prev buttons
  --------------------
*/

.slideshow-next,
.slideshow-previous {
  @include hide-text;
  appearance: none;
  background-color: rgba(palette(gray, dark), .6);
  background-repeat: no-repeat;
  background-size: 32px 72px;
  border: none;
  display: none;
  height: vr(20);
  margin: vr(-10) 0 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transition: $transition--basic;
  width: vr(16);

  &:hover {
    background-color: palette(blue);
  }
}

.slideshow[min-width~="600px"] {
  .slideshow-next,
  .slideshow-previous {
    display: block;
  }
}

.slideshow-next {
  background-image: url(/sites/all/themes/rotary_rotaryorg/images/slideshow-next.svg);
  background-position: 27px 50%;
  right: vr(-16);
}

.slideshow-previous {
  background-image: url(/sites/all/themes/rotary_rotaryorg/images/slideshow-prev.svg);
  background-position: 39px 50%;
  left: vr(-16);
}

[data-whatintent="mouse"] {
  .slideshow:hover {
    .slideshow-next {
      right: vr(-2);

      &:hover {
        right: 0;
      }
    }

    .slideshow-previous {
      left: vr(-2);

      &:hover {
        left: 0;
      }
    }
  }
}
