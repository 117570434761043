li.search-results-item {
  ul {
    margin-top: 0.5rem;
  }

  li.search-result-meta {
    display: inline-block;

    & + li:before {
      display: inline;
      content: "|";
      margin: 0 1rem;
    }
  }
}
