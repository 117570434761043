// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content/
.a11y-sr-only {
  @include a11y--visually-hide;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see https://www.viget.com/articles/skip-link-primer
// Credit: HTML5 Boilerplate
.a11y-sr-only-focusable {
  &:active,
  &:focus {
    @include a11y--visually-show;
  }
}

// skip links
.a11y-skip-link {
  @include smooth-text;
  @extend .a11y-sr-only;
  @extend .a11y-sr-only-focusable;
  background-color: palette(blue, x-dark);
  color: #fff;
  display: block;
  font-weight: $font-weight--bold;
  padding: 20px;
  position: absolute;
  z-index: $zindex--a11y;

  [data-whatinput="keyboard"] &:focus {
    background-color: palette(blue, x-dark);
    color: #fff;
  }
}

// focuser overlay
.a11y-focuser {
  border: 2px solid darken(palette(orange), 5);
  border-radius: 4px;
  box-shadow: 0 0 10px 2px darken(palette(orange), 5);
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  z-index: $zindex--a11y;

  [data-whatinput="keyboard"] &.-focus {
    transition:
      height .2s $easing-base,
      left .2s $easing-base,
      top .2s $easing-base,
      width .2s $easing-base;
    visibility: visible;
  }

  // don't show on modal because of issue with position:fixed
  [data-scroll="false"] & {
    display: none;
  }
}
